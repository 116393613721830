import React, { useContext,useState, useEffect } from 'react';
import { getUserFaqs, getExpertFaqs } from '../../services/user';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div
            onClick={decoratedOnClick}
            className='p-3 fs-4'
        >
             <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
            {isCurrentEventKey? <i className="fa-solid fa-square-minus toggle-on text-primary fs-1"></i>: <i className="fa-solid fa-square-plus toggle-off fs-1"></i>
            }</div>
            <span class="text-gray-700 fw-bold cursor-pointer mb-0">{children}</span>
        </div>
    );
}

function Faqs() {
    const userType = sessionStorage.getItem('userType');
    const [faqs, setFaqs] = useState([]);
    useEffect(() => {
        getFaqs();
    }, [])
    const getFaqs = async () => {
        if (userType === "U") {
            try {
                const response = await getUserFaqs();
              //  console.log(response.data.data);
                setFaqs(response.data.data);
            } catch (error) {
                setFaqs([]);
                console.error(error);
            }
        } else {
            try {
                const response = await getExpertFaqs();
                //console.log(response.data.data);
                setFaqs(response.data.data);
            } catch (error) {
                setFaqs([]);
                console.error(error);
            }
        }
    };
    return (
        <> <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Faqs
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/" className="text-muted text-hover-primary">
                            Home                            </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-500 w-5px h-2px"></span>
                    </li>

                    <li className="breadcrumb-item text-muted">
                        FAQs                                            </li>

                </ul>
            </div>

        </div>
            <div className="row g-5 g-xl-10 mb-80">
                <div className="col">
                    <div className="card card-flush h-md-100">
                        <div className="card-header pt-7 ps-8" style={{ marginLeft: "30px" }} >
                            <h1 className="text-gray-900 fw-bolder pt-9">
                                Frequently Asked Questions
                            </h1>
                            <br></br>
                            <div className="row mb-12">
                                <div className="col-md-12 pe-md-10 mb-10 mb-md-0">
                                <Accordion defaultActiveKey="0">
                                    {faqs.length > 0 && faqs.map((data, index) => {
                                        return (
                                            <div className="m-0">
                                                 <div key = {data.faqId}>
                        <div className='p-0'>
                            <ContextAwareToggle eventKey={index.toString()}>
                            {data.question}
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                            <div dangerouslySetInnerHTML={{ __html: data.answer }}></div>
                            </div>
                        </Accordion.Collapse>
                    </div>

                                               
                                                <div className="separator separator-dashed"></div>
                                            </div>
                                        )
                                    })
                                    }
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faqs;