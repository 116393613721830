import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { purchasePackage } from '../../services/userpackage';
import verify from "../../assets/images/verify.png";

function PaymentSuccess() {
  const params = useParams();
  const packageId = params.packageId;
  const couponId = params.couponId;
  useEffect(() => {
    packagePurchase();
  }, []);
  const packagePurchase = async () => {
    try {
      const response = await purchasePackage(packageId,couponId);
      window.setTimeout(function () {
        window.location.replace("/packages");
      }, 3000);
    } catch (error) {
    }
  };

  return (
    <>
          <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

                <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">



                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                  </div>

                </div>
              </div>

              <div id="kt_app_content" className="app-content  flex-column-fluid " >


                <div id="kt_app_content_container" className="app-container  container-xxl ">
                  <div className="row g-5 g-xl-10 mb-80">
                    <div className="col">

                      <div className="card card-flush h-md-100">
                        <div className="card-body pt-7">
                          <h3 className="card-title align-items-start flex-column text-center">
                            <img src={verify} className="h-60px h-lg-75px" />
                            <h1 className="card-label fw-bold text-gray-800 fs-35 mt-10">Thank You</h1>
                            <h1 className="card-label fw-bold text-gray-800">for Purchasing Package.</h1>
                          </h3>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            </div>
    </>
  );
}

export default PaymentSuccess;