import axiosInterceptor from '../helpers/axiosInterceptor';
import { EXPERT_ASSIGNED_PACKAGES, EXPERT_ASSIGN_PACKAGE, EXPERT_PURCHASE_PACKAGE, EXPERT_THEME_PACKAGES, EXPERT_UNASSIGN_PACKAGE, GET_PURCHASED_PACKAGES } from "../Constants";
import { GET_PACKAGE_REPORT } from "../Constants";
import { PURCHASE_PACKAGE } from "../Constants";
import { GET_THEME_LIST } from "../Constants";
import { GET_PACKAGE_LIST, EXPERT_PACKAGE,REPORT_DOWNLOAD,EXPERT_REMAINING_LICENSES,EXPERT_REMOVE_LICENSE } from "../Constants";


function getPurchasedPackages() {
      return axiosInterceptor.get(GET_PURCHASED_PACKAGES);
}

function getPackageReport(userId,packageId) {
      return axiosInterceptor.get(GET_PACKAGE_REPORT + userId + "/" + packageId);
}

function purchasePackage(packageId,couponId) {
      return axiosInterceptor.post(PURCHASE_PACKAGE,
            {
                  id: packageId,
                  couponId: couponId
            });
}
function expertPurchasePackage(packageId, licenseCount,couponId) {
      return axiosInterceptor.post(EXPERT_PURCHASE_PACKAGE,
            {
                  packageId: packageId,
                  licenseCount: licenseCount,
                  couponId: couponId
            });
}

function expertAssignPackage(packageId, userId) {
      return axiosInterceptor.post(EXPERT_ASSIGN_PACKAGE,
            {
                  packageId: packageId,
                  userId: userId
            });
}

function unAssignPackage(packageId, userId) {
      return axiosInterceptor.post(EXPERT_UNASSIGN_PACKAGE,
            {
                  packageId: packageId,
                  userId: userId
            });
}

function expertPackage(packageId) {
      return axiosInterceptor.get(EXPERT_PACKAGE + packageId)
}

function expertAssignedPackages(userId) {
      return axiosInterceptor.get(EXPERT_ASSIGNED_PACKAGES + userId)
}

function getThemeList() {
      return axiosInterceptor.get(GET_THEME_LIST);
}

function getPackageList(themeId) {
      return axiosInterceptor.get(GET_PACKAGE_LIST + themeId);
}

function getexpertPurchasedPackages(themeId) {
      return axiosInterceptor.get(EXPERT_THEME_PACKAGES + themeId);
}

function reportDownload(userId,packageId) {
      return axiosInterceptor.get(REPORT_DOWNLOAD + userId + "/" + packageId);
}

function expertRemainingLicenses(packageId) {
      return axiosInterceptor.get(EXPERT_REMAINING_LICENSES + packageId)
}

function expertRemoveLicense(packageId, licenseCount) {
      return axiosInterceptor.post(EXPERT_REMOVE_LICENSE,
            {
                  packageId: packageId,
                  licenseCount: licenseCount
            });
}


export { getPurchasedPackages, getPackageReport, purchasePackage, getThemeList, getPackageList, expertPackage, expertPurchasePackage, 
      getexpertPurchasedPackages, expertAssignedPackages, expertAssignPackage, unAssignPackage,reportDownload,
      expertRemainingLicenses,expertRemoveLicense };