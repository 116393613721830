import axiosInterceptor from '../helpers/axiosInterceptor';
import { GET_TICKET_LIST, TICKET_CREATE, GET_SUPPORT_ID_DETAILS, SEND_MESSAGE, GET_EXPERT_RECENT_TICKET_LIST, GET_USER_RECENT_TICKET_LIST, GET_EXPERT_TICKET_LIST, UPDATE_SUPPORT_TICKET } from "../Constants";

function createTicket(subject,priority,message) {
    return axiosInterceptor.post(TICKET_CREATE,
        {
            "subject": subject,
            "priority": priority,
            "message": message
          });
}
function getTicketsList() {
    const userType = sessionStorage.getItem('userType');
    const API = userType==='E'?GET_EXPERT_TICKET_LIST: GET_TICKET_LIST;
    return axiosInterceptor.get(API);
}
function getRecentTicketsList() {
    const userType = sessionStorage.getItem('userType')
    const API = userType==='E'?GET_EXPERT_RECENT_TICKET_LIST: GET_USER_RECENT_TICKET_LIST;
    return axiosInterceptor.get(API);
}
function getTicketDetails(ticketId) {
    return axiosInterceptor.get(GET_SUPPORT_ID_DETAILS + ticketId);
}
function sendMessage(ticketId, message) {
    return axiosInterceptor.post(SEND_MESSAGE,
        {
            "supportId": ticketId,
            "message": message
          });
}
function updateTicketStatus(ticketId, status) {
    return axiosInterceptor.post(UPDATE_SUPPORT_TICKET,
        {
            "supportId": ticketId,
            "status": status
          });
}

export {createTicket, getTicketsList, getTicketDetails, sendMessage, getRecentTicketsList, updateTicketStatus};