import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import ViewModal from './ViewModal';

function AssesmentPackageTable(props) {
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");
    const [showAssesment, setShowAssesment] = useState(false);
    const [assessments, setAssesments] = useState([])
    const columns = [
        { label: "Packge", accessor: "packageName", sortable: true },
        { label: "Purchased", accessor: "purchased", sortable: true },
        { label: "Assigned", accessor: "assigned", sortable: true },
        { label: "Remaining", accessor: "remaining", sortable: true },

    ];

    const [tableData, setTableData] = useState(props.tableData)
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };
    const handleSortingChange = (accessor) => {
        const sortOrder =
            accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };
    const handleViewAssesment = (data) =>{
        setShowAssesment(!showAssesment)
        if(data) {
            setAssesments(data)
        }
    }

    return (
        <>
            <div>
                <Table responsive="sm" className='stripe hover row-border table-striped table-bordered assesment-table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            {columns.map(({ label, accessor, sortable },i) => {
                                const cl = sortable
                                    ? sortField === accessor && order === "asc"
                                        ? "fa-solid fa-chevron-up ms-1"
                                        : sortField === accessor && order === "desc"
                                            ? "fa-solid fa-chevron-down ms-1"
                                            : "default"
                                    : "";
                                return (
                                    <th
                                        key={'haccessor'+i}
                                        onClick={sortable ? () => handleSortingChange(accessor) : null}
                                        // className={cl}
                                    >
                                        {label}<i className={cl}></i>
                                    </th>
                                );
                            })}
                            <th>View</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data, index) => {

                            return (
                                <tr key={data.id}>
                                    <td>{index + 1}</td>
                                    {columns.map(({ accessor },index) => {
                                        const tData = (data[accessor] || data[accessor] === 0) ? data[accessor] : "——";
                                        return <td key={'accessor'+index}>{tData}</td>;
                                    })}
                                    <td key = {data.packageId+index}>
                                        <i class="fa-solid fa-eye" onClick={()=>handleViewAssesment(data.assessments)}></i>
                                    </td>
                                    <td key = {data.packageId+'action'+index}>
                                        {/* <i className="fa-solid fa-user-pen fs-3 me-3" style={{ color: "#17c653" }}></i> */}
                                        <a href = {`/packages/purchase/${data.packageId}`}><i class="fa-solid fa-shop"></i></a>
                                         {data.remaining > 0 && <a className="ml-30" href = {`/packages/remove/${data.packageId}`}>
                                            <i class="fa-solid fa-circle-xmark"></i></a> }
                                        {/* <i className="fa-solid fa-circle-xmark fs-3" style={{ color: "#f8285a" }}></i> */}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                {<ViewModal handleClose = {handleViewAssesment} show={showAssesment} assessments={assessments}/>}
            </div>
        </>
    )
}
export default AssesmentPackageTable;