import React, { useState, useEffect } from 'react';
import { getTicketDetails, sendMessage, updateTicketStatus } from '../../../services/support';
import { useParams } from 'react-router-dom';
import './support.css'
import { Button, Table } from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { Link } from 'react-router-dom';




function TicketsInfo() {
    let { id } = useParams();
    const [ticketInfo, setticketInfo] = useState([]);
    const [message, setMessage] = useState();
    const [alertMsg, setAlertMsg] = useState(false);
    const [closeBtn, showCloseBtn] = useState(false);
    const [openBtn, showOpenBtn] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [errDescription, setErrDescription] = useState(false);

    useEffect(() => {
        ticketDetails();
    }, [])
    const ticketDetails = () => {
        getTicketDetails(id).then((result) => {
            setticketInfo(result.data.data)
            const userType=sessionStorage.getItem("userType");
             if(userType == "U" || userType == "EU"){
                if(result.data.data.status == "C"){
                     showOpenBtn(true);
                     showCloseBtn(false);
                }else{
                    showOpenBtn(false);
                    showCloseBtn(false);
                }
             }
             if(userType == "E"){
                if(result.data.data.status == "O"){
                    showCloseBtn(true);
                    showOpenBtn(false);
                }else{
                    showCloseBtn(false);
                    showOpenBtn(false);
                }
             }
        })
    }
    const handleChange = (e) => {
        setMessage(e.target.value);
    }
    const backBtn = () => {
        window.location.href = '/support';
    }
    const sendSupportMessage = () => {
        if (message) {
            sendMessage(id, message).then((res) => {
                ticketDetails();
                setMessage("")
                // alert("success")
                setAlertMsg(true)
            })
        }else{
                setErrDescription(true);
        }
    }
    const handleClose = (status) => {
        updateTicketStatus(ticketInfo.supportId, status).then((res) => {
            console.log(res)
            setAlertSuccess(true);
            if(status=="O"){
                setSuccessMsg("Ticket has been ReOpened");
            }else{
                setSuccessMsg("Ticket has been Closed");  
            }
            ticketDetails();
        })
    }
    return (
        <>
         <div className='d-flex flex-stack'>
                <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Support
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">Support</li>
                        </ul>
                    </div>
                </div>
               
                </div>
         <Alert className="login-alerts mt-30" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                {successMsg}
                            </Alert>
            <div style={{ boxShadow: "0 8px 6px 1px #ededed" }}>
                <div class="card mt-5 mb-80" id="kt_profile_details_view">
                    <div class="card-header">

                        <div class="m-0 py-4">
                            <h3 class="fw-bold m-0 pe-4" style={{display:"inline-block"}}>{ticketInfo.subject}</h3>
                            {closeBtn?
                            <Button variant="primary" className='btn-right ml-30' onClick={() => handleClose("C")}>Close</Button>
                            :null}
                           {openBtn?
                            <Button variant="primary" className='btn-right ml-30' onClick={() => handleClose("O")}>Re-Open</Button>
                            :null}
                        </div>


                    </div>
                    <div class="card-body p-5">
                        {/* <h3>Packages List</h3> */}
                        {ticketInfo?.userDetails?.map((data) => {
                            return (
                                <div className='px-4'>

                                    <Table striped borderless hover>
                                        <tbody>
                                            <tr>
                                                <td width={5}>
                                                    <div data-letters={data.name[0]} />
                                                    <div class="vertical-line" style={{ height: "45px" }}></div>
                                                </td>
                                                <td>
                                                    <div className='pb-4'>
                                                        <div className='namestyles fs-4'>{data.name}</div>
                                                        <div className='mailstyles'>{moment(data.updatedTime).format("DD MMM,YYYY HH:mm")}</div>
                                                    </div>
                                                    <div>{data.message}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>
                            )
                        })}

<textarea style={{ boxShadow: "rgb(217 214 214) 0px 8px 6px 1px", margin: "23px 0px" }} class="form-control form-control-solid" rows="4" name="description" placeholder="Type your ticket description" onChange={handleChange} value={message} disabled={ticketInfo.status === "C"}>
            </textarea>
            {errDescription == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Description</small></span> : null}
            <Button variant="primary" className='button-styles ml-30' onClick={sendSupportMessage} disabled={ticketInfo.status === "C"}>Send</Button>
            <button className='button-styles btn btn-secondary' onClick={backBtn}>Back</button>
                    </div>
                    
                </div>

            </div>
            
            {alertMsg ?
                <SweetAlert title="Message Sent!" onConfirm={() => setAlertMsg(false)} onCancel={() => setAlertMsg(false)} />
                : <></>}
        </>
    )
}
export default TicketsInfo;