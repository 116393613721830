import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

function ViewModal({ show, handleClose, assessments }) {

    return (
        <>
            <div>
                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title><h1>Package Assessments</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {assessments.map((data)=>{
                        return (<div id="kt_job_1_1" class="collapse show fs-6 ms-1">
                            <div class="mb-4">
                                <div class="d-flex align-items-center ps-10 mb-n1">
                                    <span class="bullet me-3"></span>
                                    <div class="text-gray-900 fw-semibold fs-6">
                                        {data}
                                    </div>
                                </div>
                            </div>
                        </div>)})}
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}
export default ViewModal;