import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function SuccessModal(props) {

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                    {/* <h4> */}
                    <i class="fa-regular fa-circle-check" style={{ color: "#a5dc86", fontSize: "80px", marginBottom: "20px" }}></i>
                    {/* </h4> */}
                    <p className='swal-text'>
                        {props.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Ok</Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SuccessModal;


