import React, { useState, useEffect } from 'react';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link } from 'react-router-dom';
import AssessmentToolBar from '../UserAssessments/AssessmentToolBar';
import { getPurchasedPackages } from '../../services/userpackage';
import { startAssessment } from '../../services/userassessment';
import ThemesLayout from '../UserAssessments/Themes';
import PackagesLayout from '../UserAssessments/Packages';
import PackageSummaryLayout from '../UserAssessments/PackageSummary';
import PackageListLayout from '../UserAssessments/PackageList';
import ReportLayout from '../Report/PackageReport';



function PackageList() {
  const [assessmentList, setAssessmentList] = useState();
  const [left, setLeft] = React.useState([]);
  const [packageId, setPackageId] = useState();
   const [themeId, setThemeId] = useState();
  const [page, setPage] = useState();
  const [defaultPage, setDefaultPage] = useState(true);

  const getPackageId = (packageId) =>{
     setPackageId(packageId);
 }

 const getThemeId = (themeId) =>{
     setThemeId(themeId);
 }

  const start = async (packageId) => {
    sessionStorage.setItem('packageId', packageId);
    window.location.replace("/assessments");
  };
  const getPage = (page) => {
      setDefaultPage(false);
      setPage(page);
  };

  const report = async (packageId) => {
    sessionStorage.setItem('packageId', packageId);
    window.location.replace("/packages/report");
  };


  return (
    <>
      <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
        {defaultPage ?
        <PackageListLayout packageId={getPackageId} page={getPage}/>
        :
        <>
       {page === 'Report' ? (
         <ReportLayout packageId={packageId}/>
       )
       : 
        <>
       {page === 'Themes' ? (
         <ThemesLayout themeId={getThemeId} page={getPage}/>
       )
       : 
          <>
       {page === 'PackageList' ? (
          <PackagesLayout themeId={themeId} packageId={getPackageId} page={getPage}/>
       )
       : 
        <>
       {page === 'PackageSummary' ? (
         <PackageSummaryLayout packageId={packageId} page={getPage}/>
       )
       : 
       <PackageListLayout packageId={getPackageId} page={getPage}/>
       }
       </>
       }
       </>
       }
       </>
       }
              </>

        }
        </div>
        </div>
        
    </>
  );
}

export default PackageList;