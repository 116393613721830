import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';


function AlertModal(props) {


    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                    <i class="fa-solid fa-circle-exclamation" style={{ fontSize: "80px", marginBottom: "20px" }}></i>
                    <p className='swal-text'>
                        {props.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AlertModal;


