export const industries = [
    { "value": "agriculture", title: "Agriculture" },
    { "value": "mining", title: "Mining" },
    { "value": "banking", title: "Banking" },
    { "value": "construction", title: "Construction" },
    { "value": "retail", title: "Retail" },
    { "value": "healthcare", title: "Healthcare" },
    { "value": "education", title: "Education" },
    { "value": "research", title: "Research" },
    { "value": "finance", title: "Finance" },
    { "value": "information technology", title: "Information Technology(IT)" },
]