import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import {createTicket} from '../../../services/support';

function CreateTicket({ show, handleClose, recallAPI }) {
    const [ticketData, setticketData] = useState({ subject: "", priority: "Low", description: "" });
    const [errors, setErrors] = useState({ subject: false, description: false })
    const handleTicketDataChange = (e) => {
        setErrors({ ...errors, [e.target.name]: false })
        setticketData({ ...ticketData, [e.target.name]: e.target.value })
    }
    const handleCloseForm = () => {
        setticketData({ subject: "", priority: "1", description: "" })
        handleClose()
    }
    const checkErrors = () => {
        const { subject, description } = ticketData
        if (!subject) {
            setErrors({ ...errors, subject: true })
            return true;
        }
        if (!description) {
            setErrors({ ...errors, description: true })
            return true;
        }
        return false;
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!checkErrors()) {
            createTicket(ticketData.subject, ticketData.priority, ticketData.description).then((data) => {
                if (data.data.status) {
                    handleCloseForm()
                    recallAPI(true)
                }

            })
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleCloseForm}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                    <div class="text-center">
                        <h1>Create Ticket</h1>

                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form id="kt_modal_new_ticket_form" class="form needs-validation" >

                        <div class="d-flex flex-column mb-8 fv-row">

                            <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span class="required">Subject</span>
                            </label>
                            <input type="text" class="form-control form-control-solid" placeholder="Enter your ticket subject" name="subject" value={ticketData.subject} onChange={handleTicketDataChange} />
                            {errors.subject && <div class="invalid-error">
                                Please write the Subject.
                            </div>}
                        </div>
                        <div class="row g-9 mb-8">
                            <div class="col-md-6 fv-row">
                                <label class="fs-6 fw-semibold mb-2">Priority</label>
                                <select class="form-select form-select-solid" data-control="select2" data-placeholder="Open" data-hide-search="true" value={ticketData.priority} name="priority" onChange={handleTicketDataChange}>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    
                                </select>
                            </div>

                        </div>
                        <div class="d-flex flex-column mb-8 fv-row">
                            <label class="fs-6 fw-semibold mb-2 required">Description</label>

                            <textarea class="form-control form-control-solid" rows="4" name="description" placeholder="Type your ticket description" value={ticketData.description} onChange={handleTicketDataChange}>
                            </textarea>
                            {errors.description && <div class="invalid-error">
                                Please write the Description.
                            </div>}
                        </div>

                        <div class="text-center">
                            <button type="reset" id="kt_modal_new_ticket_cancel" class="btn btn-secondary me-3" onClick={handleCloseForm}>
                                Cancel
                            </button>

                            <button class="btn btn-primary" onClick={handleSubmit}>
                                <span class="indicator-label">
                                    Submit
                                </span>
                            </button>
                        </div>

                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}
export default CreateTicket;

