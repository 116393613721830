import axiosInterceptor from '../helpers/axiosInterceptor';
import { EXPERT_ADD_USER, EXPERT_DELETE_USER, EXPERT_EDIT_USER, EXPERT_GET_USER_DETAILS, GET_EXPERT_USERS_LIST, VALIDATE_EXPERT_COUPON } from "../Constants";

function getExpertUserList() {
    return axiosInterceptor.get(GET_EXPERT_USERS_LIST);
}
function addUser(fname, lname, email,timeZone) {
    return axiosInterceptor.post(EXPERT_ADD_USER,
        {
            "firstname": fname,
            "lastname": lname,
            "email": email,
            "timeZone": timeZone
        });
}
function updateUser(fname, lname, userId,timeZone) {
    return axiosInterceptor.post(EXPERT_EDIT_USER,
        {
            "firstname": fname,
            "lastname": lname,
            "userId": userId,
            "timeZone": timeZone
        });
}
function deleteUser(id) {
    return axiosInterceptor.post(EXPERT_DELETE_USER,
        {
            "id": id
        });
}
function getUserDetails(id) {
    return axiosInterceptor.get(EXPERT_GET_USER_DETAILS + id);
}

function validateExpertCoupon(code,quantity,packageId) {
    return axiosInterceptor.get(VALIDATE_EXPERT_COUPON + code + "/" + quantity + "/" + packageId);
  }
export {getExpertUserList, addUser, deleteUser, getUserDetails, updateUser,validateExpertCoupon};