import { Alert } from "react-bootstrap";

function ToastModal(props) {


    return (
        <>
            <Alert className="login-alerts position-absolute top-0 end-0 p-3 mt-100 mr-20" variant="success">
            {props.message}
                        </Alert>
            {/*<div class="toast-container position-absolute top-0 end-0 p-3 text-white bg-primary mt-100 mr-20" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-header">
                    {props.message}
                    </div>
                    <button type="button" class="btn-close ml-30" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
    </div>*/}

        </>
    )
}
export default ToastModal;


