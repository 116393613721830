import { useEffect, useState,useMemo } from "react";
import { getRecentTicketsList } from "../../../services/support";
import "./Overview.css";
import moment from "moment";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { _ } from "gridjs-react";

function DashboardTable() {
    const [userData, setUserData] = useState([]);
    const status = { "O": "Open", "C": "Closed" }
    const userType = sessionStorage.getItem('userType');
   
    useEffect(()=>{
        getRecentList();
    },[])
    const getRecentList = () => {
        getRecentTicketsList().then((res)=>{
            setUserData(res.data.data);
        })
    }

    return (
        <>
            <div className="row g-5 g-xl-10 mb-80">
                <div className="col">

                    <div className="card card-flush h-md-100">
                        <div className="card-header pt-7 ps-8">
                                <h1 className="text-center">Support</h1>

                        </div>

                        <div className="card-body pt-1">
                            <div className="table-responsive">
                            {userType==="E"?
        <Grid 
        data={userData.map(x => [x.name,
            x.subject, x.priority,
            status[x.status],
            moment(x.updatedTime).format("DD-MM-YYYY HH:mm"),
            _(<a href={"/support/view/" + x.supportId}><i className="fa-solid fa-eye fs-4 text-gray-600 ps-1" ></i></a>)
          ])}
        columns={["Name","Subject", "Priority","Status", "Updated Time", "View"]}
        pagination={{
          limit: 10
        }}
        sort={{
          multiColumn: false
        }}
        search
      
      /> :
      <Grid 
      data={userData.map(x => [
          x.subject, x.priority,
          status[x.status],
          moment(x.updatedTime).format("DD-MM-YYYY HH:mm"),
          _(<a href={"/support/view/" + x.supportId}><i className="fa-solid fa-eye fs-4 text-gray-600 ps-1" ></i></a>)
        ])}
      columns={["Subject", "Priority","Status", "Updated Time", "View"]}
      pagination={{
        limit: 10
      }}
      sort={{
        multiColumn: false
      }}
      search
    
    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashboardTable;