import React, { useState, useEffect } from 'react';
import {getPackageReport,reportDownload} from '../../services/userpackage';
import { Link } from 'react-router-dom';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";


function PackageReport() {

    const [assessmentList, setAssessmentList] = useState();
    const [packageName, setPackageName] = useState();
    const [left, setLeft] = React.useState([]);
    const [alertMsg, setAlertMsg] = useState(false);
    const [showResource, setShowResource] = useState(false);


    useEffect(() => {
         if (sessionStorage.getItem('packageId')!=null) {
           getReport();
         }else{
           window.location.href = '/packages';
         }
    }, []);
    const getReport = async () => {
        setAlertMsg(true);
        try {
            const response = await getPackageReport(sessionStorage.getItem('userId'),sessionStorage.getItem('packageId'));
           //console.log(response.data.data.reportDTO);
            if(response.data.data.reportDTO.length>0){
            setAssessmentList(response.data.data.reportDTO);
            setPackageName(response.data.data.packageName)
            setShowResource(response.data.data.showResource);
           }else{
            setAssessmentList(null);
            window.location.href = '/packages';
           }
           setAlertMsg(false);
        } catch (error) {
            setAssessmentList(null);
            console.error(error);
            setAlertMsg(false);
            window.location.href = '/packages';
        }
    };

    const downloadReport = async () => {
        try {
            const response = await reportDownload(sessionStorage.getItem('userId'),sessionStorage.getItem('packageId'));
            if(response.data.status){
                window.location.href = response.data.data;
            }
        } catch (error) {
            setAssessmentList(null);
            console.error(error);
        }
    };

    return (
        <>
 <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/packages" className="btn btn-sm fw-bold btn-secondary">Back</Link>
                        </div>
                    </div>
                       
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button onClick={downloadReport} className="btn btn-sm fw-bold btn-primary">Download</button>
                        </div>
                   
                </div>
            </div>
              
                                <div id="kt_app_content_container" className="app-container  container-xxl ">
                                    <div className="row g-5 g-xl-10 mb-80">

                                        <div className="col">
                                        {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
                                            <div className="card card-flush h-md-100">
                                                <div className="card-body pt-7"  id="divToPdf">
                                                    <div className="card-title align-items-start flex-column">
                                                        <h1 className="card-label fw-bold text-gray-800 fs-35 mt-10 mb-10 text-center">
                                                            {packageName} Report</h1>

                                                          <div className="table-responsive bg-white px-lg-20 px-0">
                                                            <table className="table table-striped fs-4 border border-dark-subtle text-center" cellPadding="5">
                                                                <thead className="bg-body-secondary border border-dark-subtle">
                                                                </thead>
                                                                 <tbody className="border border-dark-subtle">
                                                                   <tr className="fw-bold">
                                                                        <th className="border-start-0 br-none">
                                                                        <span className="report-circle">1</span>
                                                                        </th>
                                                                        <th className="border-start-0 br-none">
                                                                        <span className="report-circle">2</span>
                                                                        </th>
                                                                        <th className="border-start-0">
                                                                        <span className="report-circle">3</span>
                                                                        </th>
                                                                    </tr>
                                                                      <tr className="fw-bold">
                                                                        <th className="border-start-0 br-none">OK</th>
                                                                        <th className="border-start-0 br-none">Borderline</th>
                                                                        <th className="border-start-0">Flagged</th>
                                                                    </tr>
                                                                 </tbody>
                                                            </table>
                                                          </div>

                                                        <div className="table-responsive bg-white px-lg-20 px-0 mt-20">
                                                            <h1 class="text-center mb-10">Summary</h1>
                                                            <table className="table table-striped fs-4 text-center border border-dark-subtle" cellPadding="5">
                                                                <thead className="bg-body-secondary border border-dark-subtle">
                                                                </thead>

                                                                <tbody className="border-dark-subtle">
                                                                    {(assessmentList ? assessmentList : left).map((key, value) => (
                                                                        <tr className="fw-bold">
                                                                            <th className="border-start-0 br-none">{key.type}</th>  
                                                                            <th className="border-start-0 br-none">{key.name}</th>
                                                                            {key.flagged ? <>
                                                                                <th className="border-start-0 br-none">
                                                                                <span className="report-circle">1</span>
                                                                                </th>
                                                                                <th className="border-start-0 br-none">
                                                                                <span className="report-circle">2</span>
                                                                                </th>
                                                                                 <th className="border-start-0">
                                                                                  <span className="report-circle-active">3</span>
                                                                                 </th>
                                                                            </>
                                                                                : 
                                                                                <>
                                                                                 {key.flag === 1 ?
                                                                                 <>
                                                                                    <th className="border-start-0 br-none">
                                                                                  <span className="report-circle">1</span>
                                                                                 </th>
                                                                                  <th className="border-start-0 br-none">
                                                                                  <span className="report-circle-active">2</span>
                                                                                 </th> 
                                                                                 </>
                                                                                 :
                                                                                  <>
                                                                                    <th className="border-start-0 br-none">
                                                                                  <span className="report-circle-active">1</span>
                                                                                 </th>
                                                                                  <th className="border-start-0 br-none">
                                                                                  <span className="report-circle">2</span>
                                                                                 </th> 
                                                                                 </>
                                                                                 }
                                                                                    <th className="border-start-0 br-none">
                                                                                    <span className="report-circle">3</span>
                                                                                    </th>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-responsive bg-white px-lg-20 px-0 mt-20">
                                                            <h1 class="text-center mb-10">Diagnostic Details</h1>
                                                            <table className="table table-striped fs-4 border border-dark-subtle" cellPadding="5">
                                                                <thead className="bg-body-secondary border border-dark-subtle">
                                                                    <tr className="fw-bold">
                                                                        <th className="ps-md-10 ps-5">Assessment</th>
                                                                        <th className="border-start-0">Details</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="border border-dark-subtle">
                                                                    {(assessmentList ? assessmentList : left).map((key, value) => (
                                                                        <tr>
                                                                            <td className="ps-md-10 ps-5">{key.name}</td>
                                                                            {key.flagged ? <>
                                                                                <td className="ps-md-10 ps-5">
                                                                                    <div dangerouslySetInnerHTML={{ __html: key.message }}></div>
                                                                                </td>
                                                                            </>
                                                                                : <>
                                                                                 {key.flag === 1 ? <>
                                                                                    <td className="ps-md-10 ps-5">
                                                                                    <div dangerouslySetInnerHTML={{ __html: key.message }}></div>
                                                                                </td>
                                                                                  </> : <>
                                                                                    <td className="ps-md-10 ps-5">Not flagged</td>
                                                                                    </>
                                                                                }
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                  {showResource ? 
                                                        <div className="table-responsive bg-white px-lg-20 px-0 mt-20">
                                                            <h1 class="text-center mb-10">Resource Recommendations</h1>
                                                            <table className="table table-striped fs-4 border border-dark-subtle" cellPadding="5">
                                                                <thead className="bg-body-secondary border border-dark-subtle">
                                                                    <tr className="fw-bold">
                                                                        <th className="ps-md-10 ps-5">Need</th>
                                                                        <th className="border-start-0">Resource</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="border border-dark-subtle">
                                                                    {(assessmentList ? assessmentList : left).map((key, value) => (
                                                                        <>
                                                                        {key.resourceDTO!=null ?
                                                                        <>
                                                                            {key.flagged ? <>
                                                                                <tr>
                                                                                <td className="ps-md-10 ps-5">{key.resourceDTO.name}</td>
                                                                                <td className="ps-md-10 ps-5">
                                                                                    <div dangerouslySetInnerHTML={{ __html: key.resourceDTO.resources }}></div>
                                                                                </td>
                                                                                </tr>
                                                                            </>
                                                                                : <>
                                                                                 {key.flag === 1 ? <>
                                                                                    <tr>
                                                                                    <td className="ps-md-10 ps-5">{key.resourceDTO.name}</td>
                                                                                    <td className="ps-md-10 ps-5">
                                                                                    <div dangerouslySetInnerHTML={{ __html: key.resourceDTO.resources }}></div>
                                                                                </td>
                                                                                </tr>
                                                                                  </> : <>
                                                                                    </>
                                                                                }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :<></>
                                                                        }
                                                                        </>
                                                                    )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                       :<></>}


                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                   
        </>
    );
}

export default PackageReport;