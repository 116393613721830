import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { expertPackage } from '../../../services/userpackage';
import SuccessModal from '../User/SuccessModal';
import { Link } from 'react-router-dom';
import { expertCheckoutSession } from '../../../services/checkoutsession';
import loading from "../../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import PackagePrice from '../../../PackagePrice';
import { validateExpertCoupon } from  '../../../services/expertuser';


function Purchase() {
    const { id } = useParams();
    const [packageDetails, setPackage] = useState({ packageName: "", licenseCount: 0, price: 0.0 });
    const [newlicenseCount, setnewlicenseCount] = useState(0);
    const [showSuccess, setShowsuccess] = useState(false)
    const [alertMsg, setAlertMsg] = useState(false);
    const [errLicense, setErrLicense] = useState(false);

    const [coupon, setCoupon] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [errCoupon, setErrCoupon] = useState(false);
    const [errMessage, setErrMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [discount, setDiscount] = useState();
    const [discountStatus, setDiscountStatus] = useState(false);
    const [couponId, setCouponId] = useState(0);
    const [removeButton, setRemoveButton] = useState(false);
   
    var licenseInp,couponInp;

    useEffect(() => {
        licenseInp = document.getElementById("licenses");
        couponInp = document.getElementById("coupon-input");
    });

    useEffect(() => {
        getExpertPackages();
    }, [])
    const getExpertPackages = () => {
        expertPackage(id).then((res) => {
            setPackage(res.data.data);
        })
    }
    const purchasePackages = async () => {
        if (newlicenseCount <= 0) {
            setErrLicense(true);
            licenseInp.classList.add("error-input-border");
        } else{
        setAlertMsg(true);
        if((newlicenseCount * packageDetails.price) - discount <= 0){
            window.location.replace("/payment/"+id+"/0/success/"+newlicenseCount);
        }else{
        try {
            const res = await expertCheckoutSession(id, newlicenseCount,couponId);
            setAlertMsg(false);
            window.location.replace(res.data.data);
        } catch (error) {
            setAlertMsg(false);
        }
        }
    }

    }
    const handleShowSuccess = () => {
        setShowsuccess(false);
        window.location.href = '/expert/packages';
    }

    const validateFields = () => {
        let pass = true;
        if (coupon.trim() == "") {
            setErrCoupon(true);
            couponInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }
    const onChangeCoupon = (val) => {
      if (errCoupon == true) {
          couponInp.classList.remove("error-input-border");
          setErrCoupon(false);
      }
      setCoupon(val);
    }

    const removeCoupon = async e => {
        setErrMessage(false);
        setCouponId(0);
        setSuccessMessage(false);
        setDiscountStatus(false);
        setRemoveButton(false);
        setCoupon("");
      }
    
    const applyCoupon = async e => {
      e.preventDefault();
      if (!validateFields()) {
          return;
      } else {
        try {
          const response = await validateExpertCoupon(coupon,newlicenseCount,id)
          console.log(response.data.data.status);
          if(!response.data.data.status){
            setErrMessage(true);
            setErrorMsg(response.data.data.message);
            setSuccessMessage(false);
            setDiscountStatus(false);
            setRemoveButton(false);
          }else{
            setErrMessage(false);
            setSuccessMessage(true);
            setRemoveButton(true);
            const type = response.data.data.type;
            if(type === "F"){
              setDiscount(response.data.data.amount);
            }else{
              const per = response.data.data.percentage;
              const discount = (newlicenseCount * packageDetails.price * per)/100;
              setDiscount(discount);
            }
            setDiscountStatus(true);
            setCouponId(response.data.data.couponId);
          }
        } catch (error) {
         // console.error(error);
        }
      }
    }
    return (
        <>
              <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >

                       
<div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
        Packages
    </h1>

    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

        <li class="breadcrumb-item text-muted">
            <Link to="/" class="text-muted text-hover-primary">Home</Link>
        </li>

        <li class="breadcrumb-item">
            <span class="bullet bg-gray-500 w-5px h-2px"></span>
        </li>

        <li class="breadcrumb-item text-muted">Packages</li>

    </ul>
</div>



</div>
            <div class="card mt-5 mb-80" id="kt_profile_details_view">

                <div class="card-header cursor-pointer py-5">

                    <div class="card-title m-0">
                        <h3 class="fw-bold m-0">Purchase Package</h3>
                    </div>
                </div>

                <div class="card-body p-9">
                    {alertMsg ?
                        <SweetAlert
                            title=""
                            timeout={10000}
                            showConfirm={false}
                            style={{ width: "200px", height: "100px" }}
                        > <img src={loading} width="50px" height="50px" />
                        </SweetAlert>
                        : <></>}
                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">Package Name</label>

                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800">{packageDetails.packageName}</span>
                        </div>

                    </div>

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">Package Price</label>

                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800"> 
                             <PackagePrice price={packageDetails.price}/></span>
                        </div>

                    </div>

                    {packageDetails.licenseCount > 0 && <>

                        <div class="row mb-7">

                            <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                            Purchased Quantity
                            </label>

                            <label class="col-lg-4 fw-bold fs-6 text-gray-800">
                                {packageDetails.licenseCount}
                            </label>

                        </div>
</>}

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                            Enter Quantity
                        </label>

                        <div class="col-lg-2">
                            <input type="number" name="licenses" id="licenses" 
                            class="form-control form-control-lg form-control-solid" placeholder="No. of Licenses" value={newlicenseCount} onChange={(e) => setnewlicenseCount(e.target.value)} />
                            {errLicense == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Valid Quantity.</small></span> : null}
                        </div>
                    </div>

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                             Price
                        </label>

                        <label class="col-lg-2 fw-bold fs-6 text-gray-800">
                        <PackagePrice price={newlicenseCount * packageDetails.price}/>
                        </label>                        
                    </div>
                    {discountStatus ?<>
                    <div class="row mb-7">
<label class="col-lg-3 fw-bold fs-6 text-gray-800">
     Discount
</label>
<label class="col-lg-4 fw-bold fs-6 text-gray-800">
<PackagePrice price={discount}/>
</label>
</div> 
<div class="row mb-7">
<label class="col-lg-3 fw-bold fs-6 text-gray-800">
     Total Price
</label>
<label class="col-lg-4 fw-bold fs-6 text-gray-800">
{(newlicenseCount * packageDetails.price) - discount <= 0 ? 
<PackagePrice price={0}/> : <PackagePrice price={(newlicenseCount * packageDetails.price) - discount}/> 
}
</label>
</div>
</>:<>
<div class="row mb-7">
<label class="col-lg-3 fw-bold fs-6 text-gray-800">
     Discount
</label>
<label class="col-lg-4 fw-bold fs-6 text-gray-800">
<PackagePrice price={0}/>
</label>
</div> 
<div class="row mb-7">
<label class="col-lg-3 fw-bold fs-6 text-gray-800">
     Total Price
</label>
<label class="col-lg-4 fw-bold fs-6 text-gray-800">
<PackagePrice price={(newlicenseCount * packageDetails.price)}/>
</label>
</div>
</>}
<div class="row mb-7">
<label class="col-lg-3 fw-bold fs-6 text-gray-800">
                             Coupon Code
</label>
<div className="col-lg-2 fw-bold fs-6 text-gray-800">
                            <input type="text" id="coupon-input" value={coupon} disabled={removeButton ? "disabled" : ""}
                                        onChange={(e) => onChangeCoupon(e.target.value)}
                                        placeholder="Enter Coupon Code" name="coupon" autoComplete="off" className="me-3 form-control col-md-3 bg-transparent" />
                         <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errCoupon == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Coupon Code.</small></span> : null}
                                    {errMessage == true ? <span className="input-error-text ml-1rem" ><small>{errorMsg}</small></span> : null}
                                    {successMessage == true ? <span className="input-error-text text-success ml-1rem" ><small>Coupon Applied!</small></span> : null}
                        </div>
                          
                        <div className="col-lg-2 fw-bold fs-6 text-gray-800">
                        {removeButton ? 
                           <button onClick={removeCoupon} type="submit" className="btn btn-primary">Remove</button> : 
                           <button onClick={applyCoupon} type="submit" className="btn btn-primary">Apply</button>
                        }
                        </div>
</div>
                    <div class="row mb-7">
                        <div class="col-lg-1">
                        </div>
                        <button type="submit" class="col-lg-1 btn btn-sm btn-primary align-self-center" onClick={purchasePackages}>Purchase</button>
                        <Link to="/expert/packages" class="col-lg-1 btn btn-sm btn-secondary align-self-center ml-30">Cancel</Link>

                    </div>

                </div>
                {showSuccess && <SuccessModal show={showSuccess} onHide={handleShowSuccess} message={"Your Licenses have been updated"} />}
            </div>
        </>
    )
}
export default Purchase;