import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { expertAssignPackage, expertAssignedPackages, getThemeList, getexpertPurchasedPackages, unAssignPackage } from '../../../services/userpackage';
import SuccessModal from './SuccessModal';
import { Link } from 'react-router-dom';
import "./User.css"
import WarningModal from './WarningModal';

function AssignPackage() {
    const { name, id } = useParams();
    const [packages, setPackages] = useState([])
    const [themeList, setThemeList] = useState([])
    const [theme, setTheme] = useState();
    const [themePackage, setThemePackage] = useState();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [assignedPackages, setAssignedPackages] = useState([]);
    const [packageId, setPackageId] = useState();
    const [errPackage, setErrPackage] = useState(false);
    var packageInp;


    useEffect(() => {
        packageInp = document.getElementById("package-select");
    });
    const getTheme = async () => {
        try {
            const response = await getThemeList();
            setThemeList(response.data.data);

        } catch (error) {
            setThemeList([]);
            console.error(error);
        }
    };
    const getAssignedPackages = () => {
        expertAssignedPackages(id).then((res) => {
            setAssignedPackages(res.data.data);
        })
    }
    const handlePackage = (e) => {
        
        setThemePackage(e.target.value);
    }
    const getPackages = (themeId) => {
        getexpertPurchasedPackages(themeId).then((res) => {
            if(res.data.data.length>0){
                if (errPackage == true) {
                    packageInp.classList.remove("error-input-border");
                    setErrPackage(false);
                }
                setPackages(res.data.data);
            }else{
               setErrPackage(true);
               packageInp.classList.add("error-input-border");
            }
        
           
        })
    }
    const handleThemeChange = (e) => {
        setTheme(e.target.value);
        getPackages(e.target.value);
    }
    useEffect(() => {
        getTheme();
        getAssignedPackages();
    }, [])
    const assignPackage = () => {
        if (themePackage) {
            expertAssignPackage(themePackage, id).then((res) => {
                if (res.data.data) {
                    setShowSuccess(true)
                }
            })
        }
    }
    const handleSuccessModal = () => {
        setShowSuccess(false);
        window.location.href = '/expert/users';
    }
    const handleWarningModal = () => {
        setShowWarning(false);
    }
    const remove = (packageId) => {
        setShowWarning(true);
        setPackageId(packageId);
     }

    const removePackage = () => {
        setShowWarning(false);
       unAssignPackage(packageId, id).then((res)=>{
            if(res.data.status) {
                getAssignedPackages();
            }
        })
    }
    return (
        <>
            <div className='d-flex flex-stack'>
                <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            Users
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">Users</li>
                        </ul>
                    </div>
                </div>
               
            </div>
            <div class="card mt-5 mb-80" id="kt_profile_details_view">
                <div class="card-header cursor-pointer">

                    <div class="card-title m-0 py-4">
                        <h3 class="fw-bold m-0">Assign Package</h3>
                    </div>


                </div>
                <div class="card-body p-9">
                    <div class="row mb-7">
                        <label class="col-lg-4 fw-bold fs-6 text-gray-800">Name</label>
                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800">{name}</span>
                        </div>
                    </div>
                    <div class="row mb-7">
                        <label class="col-lg-4 fw-bold fs-6 text-gray-800">Assigned Packages</label>

                        <div class="col fv-row">

                            {assignedPackages.length > 0 && assignedPackages.map((data) => {
                                return (<div className='packages-styles'>
                                    <span class="bullet me-3 align-middle"></span>
                                    <span class="fw-semibold text-gray-800 fs-6">{data.packageName}</span>
                                    <i className="fa-solid fa-xmark ps-2" style={{ color: '#f8285a', cursor: "pointer" }} onClick={()=>remove(data.packageId)}></i>
                                </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div class="row mb-7">

                        <label class="col-lg-4 fw-bold fs-6 text-gray-800">
                            Select Theme
                        </label>

                        <div class="col-lg-8 d-flex align-items-center">
                            <select name="theme" class="form-select form-select-solid form-select-lg fw-semibold" onChange={handleThemeChange} value={theme}>
                                <option value="">Select</option>
                                {themeList.map((data) => {
                                    return (<option value={data.themeId}>{data.themeName}</option>)
                                })}
                                {/* <option value="">Theme two</option>
                            <option value="">Theme three</option> */}
                            </select>
                        </div>

                    </div>
                    <div class="row mb-7">
                        <label class="col-lg-4 fw-bold fs-6 text-gray-800">Assign New Package</label>
                        <div class="col-lg-8">
                            <select name="package" id="package-select" class="form-select form-select-solid form-select-lg fw-semibold" onChange={handlePackage} value={themePackage}>
                                <option value="">Select</option>
                                {packages.map((data) => {
                                    let duplicatePackages = assignedPackages.filter(packages=>packages.packageId === data.packageId)
                                    return (
                                        duplicatePackages.length===0&&<option value={data.packageId}>{data.packageName}</option>)
                                }
                                )}

                            </select>
                            {errPackage == true ? <span className="input-error-text ml-1rem" ><small>Please Purchase Package for Selected Theme.</small></span> : null}
                        </div>

                    </div>

                    <div class="row mb-7">
                        <div class="col-lg-1">
                        </div>
                        <button type="submit" class="col-lg-1 btn btn-sm btn-primary align-self-center" onClick={assignPackage}>Submit</button>
                        <Link to="/expert/users" class="col-lg-1 btn btn-sm btn-secondary align-self-center ml-30">Cancel</Link>

                    </div>

                </div>
            </div>
            {showSuccess && <SuccessModal show={showSuccess} onHide={handleSuccessModal} message={"You have assigned package successfully"} />}
            {showWarning && <WarningModal id={id} packageId={packageId} show={showWarning} cancel={removePackage} onHide={handleWarningModal} message={"Do you really want to unassign package?"} />}
        </>
    )
}
export default AssignPackage;