import React, { useState,useEffect } from 'react';
import './Login.css';
import { updatePassword } from '../../services/user';
import { Link,useParams } from 'react-router-dom';
import RightBanner from './RightBanner';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";
import Hide from "../../assets/images/hide.svg";
import Show from "../../assets/images/show.svg";
import logo from "../../assets/images/resetlogo.png";



function ResetPassword() {

 const params = useParams();
   const token = params.token;
    const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
   const [errPassword, setErrPassword] = useState(false);
    const [errConfirmPassword, setErrConfirmPassword] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
   const [alertSuccess, setAlertSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState();
     const [passwordMatched, setPasswordMatched] = useState(true);

     const [showPswd, setShowPswd] = useState({ new : false, confirm : false });

     const showPswdHandler = (type) => {
        let obj = JSON.parse(JSON.stringify(showPswd));
        let value = obj[type];
        obj[type] = !value;
        setShowPswd(obj);
    }

   

    var confirmPasswordInp, passwordInp;

     useEffect(() => {
        passwordInp = document.getElementById("password-input");
        confirmPasswordInp = document.getElementById("confirmPassword-input");
    });

      const validateFields = () => {
        let pass = true;
        if (password == "") {
            setErrPassword(true);
            passwordInp.classList.add("error-input-border");
            pass = false;
        }
         if (confirmPassword == "") {
            setErrConfirmPassword(true);
            confirmPasswordInp.classList.add("error-input-border");
            pass = false;
        } else {
           if(confirmPassword !== password) {
                setPasswordMatched(false);
                pass= false;
            }
        }
        return pass;
    }

     const onChangeConfirmPassword = (val) => {
        if (errConfirmPassword == true) {
            confirmPasswordInp.classList.remove("error-input-border");
            setErrConfirmPassword(false);
        }
        setConfirmPassword(val);
        setPasswordMatched(true);
    }
    const onChangePassword = (val) => {
        if (errPassword == true) {
            passwordInp.classList.remove("error-input-border");
            setErrPassword(false);
        }
        setPassword(val);
    }

  const handleSubmit = async e => {
    e.preventDefault();
     if (!validateFields()) {
            return;
        }else{
    const newPassword = password;
     try {
          setAlertMsg(true);
       const response = await updatePassword({
      token,
      newPassword
    });   
     setAlertMsg(false);
          setPassword("");
          setConfirmPassword("");
           setAlertError(false);
             setAlertSuccess(true);
        setSuccessMsg("Your Password is updated Please Login"); 
    } catch (error) {
      console.error(error);
       setErrMsg("Internal Server Error");
       setAlertMsg(false);
        setAlertError(true);
    }
        }
  }

  return(
      <>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">    
    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2 right-text">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
        <Link to="/overview" title="Logo" className="logo">
                <img src={logo} title="logo" alt="logo" className="logo-default" />
            </Link>
            <div className="w-lg-500px p-10">
                <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                    {errMsg}
                </Alert>
                <Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                    {successMsg}
                </Alert>
                {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
<form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
    <div className="text-center mb-11">
        <h1 className="text-gray-900 fw-bolder mb-3">
             Setup New Password
        </h1>   
        <div className="text-gray-500 fw-semibold fs-6">
            Have you already reset the password ?

            <Link to="/login" className="link-primary fw-bold">
                Sign in
            </Link>
        </div>
    </div>
    <div className="fv-row mb-8">
        <input type={showPswd['new'] ? "text" : "password"} value={password} id="password-input"
        onChange={(event) => onChangePassword(event.target.value)} placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent"/>
      <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler('new') }}>
                        {showPswd['new']===true ? <img src={Hide} /> : <img src={Show} />}
                    </div>
    <span className="input-pre-text"><small>&ensp;</small></span>
                    {errPassword == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Password.</small></span> : null}
        </div>

    <div className="fv-row mb-3">
     <input  type={showPswd['confirm'] ? "text" : "password"} value={confirmPassword} id="confirmPassword-input"
        onChange={(event) => onChangeConfirmPassword(event.target.value)} placeholder="Confirm Password" name="confirm-password" autocomplete="off" className="form-control bg-transparent"/>
     <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler('confirm') }}>
                        {showPswd['confirm']===true ? <img src={Hide} /> : <img src={Show} />}
                    </div>
    <span className="input-pre-text"><small>&ensp;</small></span>
                    {errConfirmPassword == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Confirm Password.</small></span> : null}
                           {passwordMatched == false ? <span className="input-error-text ml-1rem" ><small>Passwords do not match.</small></span> : null}
                    </div>

    <div className="d-grid mb-10">
        <button type="submit" id="kt_new_password_submit" className="btn btn-primary">
            
<span className="indicator-label">
    Submit</span>

<span className="indicator-progress">
    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
</span>
   </button>
    </div>

</form>
            </div>
        </div>

     
    </div>
    
    <RightBanner />
</div>
    </>
  )
}

export default ResetPassword;
