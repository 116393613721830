import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { changePassword } from '../../services/login';
import { Link } from 'react-router-dom';
import ToastModal from '../../ToastModal';
import Hide from "../../assets/images/hide.svg";
import Show from "../../assets/images/show.svg";

function ProfileChangePassword({ userId }) {
    const [userDetails, setUserDetails] = useState({ newPassword: "", confirmPassword: ""});
    const [errors, setErrors] = useState({ notCorrectPassword: false, newPassword: false, confirmPassword: false, notSame: false });
    const [showToast, setShowToast] = useState(false);
    const [showPswd, setShowPswd] = useState({ new : false, confirm : false });

    const showPswdHandler = (type) => {
        let obj = JSON.parse(JSON.stringify(showPswd));
        let value = obj[type];
        obj[type] = !value;
        setShowPswd(obj);
    }

    useEffect(() => {
        // getDetails();
    }, [])
    const handleUserDataChange = (e) => {
        setErrors({ ...errors, [e.target.name]: false, notSame: false })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }

    const checkErrors = () => {
        const { newPassword, confirmPassword } = userDetails
        /*if (!oldPassword) {
            setErrors({ ...errors, oldPassword: true })
            return true;
        }*/
        if (!newPassword) {
            setErrors({ ...errors, newPassword: true })
            return true;
        }

        if (!confirmPassword) {
            setErrors({ ...errors, confirmPassword: true })
            return true;
        }
        if (newPassword != confirmPassword) {
            setErrors({ ...errors, notSame: true })
            return true;
        }
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { confirmPassword, newPassword } = userDetails;
        if (!checkErrors()) {
            try {
                const response = await  changePassword(newPassword);
                if (response.data.status) {                    
                    setShowToast(true);
                    window.setTimeout(function () {
                        setShowToast(false);
                        sessionStorage.clear();
                        window.location.href = '/'
                    }, 2000);
    
                } else {
                    setErrors({ ...errors, notCorrectPassword: true })
                }
            } catch (error) {
               // console.error(error);
               setErrors({ ...errors, notCorrectPassword: true })
            }
        }

    }
   
    return (
        <>
            <div id="kt_app_toolbar" class=" py-3 py-lg-6 ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Profile
                    </h1>

                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                        <li class="breadcrumb-item text-muted">
                            <a href="/" class="text-muted text-hover-primary">
                                Home                            </a>
                        </li>

                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-500 w-5px h-2px"></span>
                        </li>

                        <li class="breadcrumb-item text-muted">
                            Profile                                            </li>
                    </ul>

                </div>
                {showToast && <ToastModal show={showToast} message={"Password Changed Successfully"} />}
                <div class="card mt-5 mb-80">

                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

                        <div class="card-title m-0 p-4">
                            <h1 className="text-gray-900 fw-bolder">
                                Change Password
                            </h1>
                        </div>

                    </div>

                    <div id="kt_account_settings_profile_details" class="collapse show">

                        <div class="card-body border-top p-9">
                            <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>

                            { /* <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Old Password</label>

                                 <div class="col-lg-8 fv-row">
                                        <input type={showPswd['old'] === true ? "text" : "password"} onChange={handleUserDataChange} name="oldPassword" className="form-control form-control-lg form-control-solid"
                                            placeholder="Old Password" value={userDetails.oldPassword} />
                                         <div className="show-hide-pswd-eye password-icon" onClick={() => { showPswdHandler('old') }}>
                        {showPswd['old']===false ? <img src={Show} /> : <img src={Hide} />}
                    </div>
                                        {errors.oldPassword && <div class="invalid-error input-error-text pt-2">
                                            Please Enter Old Password.
                                        </div>}
                                        {errors.notCorrectPassword && <div class="invalid-error input-error-text pt-2">
                                            Please Enter Correct Old Password.
                                        </div>}
                                    </div> 

                                </div>*/}

                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">New Password</label>

                                    <div class="col-lg-8 fv-row">
                                        <input type={showPswd['new'] ? "text" : "password"} onChange={handleUserDataChange} name="newPassword" className="form-control form-control-lg form-control-solid"
                                            placeholder="New Password" value={userDetails.newPassword} />
                                        <div className="show-hide-pswd-eye password-icon" onClick={() => { showPswdHandler('new') }}>
                        {showPswd['new']===false ? <img src={Show} /> : <img src={Hide} />}
                    </div>
                                        {errors.newPassword && <div class="invalid-error input-error-text pt-2">
                                            Please Enter New Password.
                                        </div>}
                                    </div>

                                </div>

                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Confirm New Password</label>

                                    <div class="col-lg-8 fv-row">
                                        <input type={showPswd['confirm'] ? "text" : "password"} onChange={handleUserDataChange} name="confirmPassword" className="form-control form-control-lg form-control-solid"
                                            placeholder="Confirm New Password" value={userDetails.confirmPassword} />
                                         <div className="show-hide-pswd-eye password-icon" onClick={() => { showPswdHandler('confirm') }}>
                        {showPswd['confirm']===false ? <img src={Show} /> : <img src={Hide} />}
                    </div>
                                        {errors.confirmPassword && <div class="invalid-error input-error-text pt-2">
                                            Please Enter Confirm New Password.
                                        </div>}
                                        {errors.notSame && <div class="invalid-error input-error-text pt-2">
                                            New Password and Confirm Password are not same.
                                        </div>}
                                    </div>

                                </div>


                                <div class="card-footer d-flex justify-content-end py-6 px-9">
                                    <Link to="/profile"><button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button></Link>
                                    <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Update Password</button>
                                </div>
                            </form>
                        </div>
                        {/* </form> */}
                    </div>

                </div>
            </div>
        </>
    )
}
export default ProfileChangePassword;

