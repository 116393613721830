import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link, useParams } from 'react-router-dom';
import { getAssessmentResult } from '../../services/userassessment';
import verify from "../../assets/images/verify.png";
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function UserAssessmentResult() {
  const params = useParams();
  const [assessmentId, setAssessmentId] = useState();
  const [packageId, setPackageId] = useState();
  const [message, setMessage] = useState();
  const [nextAssessmentId, setNextAssessmentId] = useState();
  const [nextSeoName, setNextSeoName] = useState();
  const [alertMsg, setAlertMsg] = useState(false);
  const [coreAssessmentId, setCoreAssessmentId] = useState('0');

  useEffect(() => {
    if (sessionStorage.getItem('packageId')!=null) {
      setPackageId(sessionStorage.getItem('packageId'));
      if (sessionStorage.getItem('assessmentId')!=null) {
        setAssessmentId(sessionStorage.getItem('assessmentId'));
        getResult();
           }else{
                window.location.href = '/assessments';
           }
         }else{
              window.location.href = '/assessments';
         }
      
  }, []);

  const getResult = async () => {
    if (sessionStorage.getItem('coreAssessmentId')!=null) {
      setCoreAssessmentId(sessionStorage.getItem('coreAssessmentId'));
    }else{
      setCoreAssessmentId('0');
    }
    setAlertMsg(true);
    try {
      const response = await getAssessmentResult(sessionStorage.getItem('assessmentId'),
                            sessionStorage.getItem('packageId'),coreAssessmentId);
      setAlertMsg(false);
      if (response.data.data != null) {
        setNextAssessmentId(response.data.data.nextAssessmentId);
        setMessage(response.data.data.message);
        setNextSeoName(response.data.data.nextSeoName);
        sessionStorage.setItem('assessmentId', response.data.data.nextAssessmentId)
      }else{
        setNextAssessmentId(null);
      }
    } catch (error) {
      setAlertMsg(false);
      console.error(error);
      setNextAssessmentId(null);
    }
  };

  const nextAssessment = async e => {
    e.preventDefault();
    window.location.replace("/assessments/" + nextAssessmentId +"/"+nextSeoName);

  }

  const viewReport = async e => {
    e.preventDefault();
    window.location.replace("/packages/report");
  }

  return (
    <>
          <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

                <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                  </div>
                </div>
              </div>

              <div id="kt_app_content" className="app-content  flex-column-fluid " >
                <div id="kt_app_content_container" className="app-container  container-xxl ">
                  <div className="row g-5 g-xl-10 mb-80">
                    <div className="col">
                    {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
                      <div className="card card-flush h-md-100">
                        <div className="card-body pt-7">
                          <div className="card-title align-items-start flex-column text-center">
                            <img src={verify} className="h-60px h-lg-75px" />
                            <h1 className="card-label fw-bold text-gray-800 mt-10">Thank You for Completing Assessment.</h1>
                            <h1 className="card-label fw-bold text-gray-800">
                              <div dangerouslySetInnerHTML={{ __html: message }}></div></h1>
                          </div>
                          <div className="col-12 text-center mb-10 mt-10 px-lg-20 px-0">
                            {nextAssessmentId === null ?
                              <button type="submit" className="btn btn-primary col-2" onClick={viewReport}>
                                <span className="indicator-label">View Report</span>
                              </button>
                              :
                              <button type="submit" className="btn btn-primary col-2" onClick={nextAssessment}>
                                <span className="indicator-label">Next Assessment</span>
                              </button>
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        
    </>
  );
}

export default UserAssessmentResult;