import React, { useState, useEffect } from 'react';
import "./profile.css"
import { getUserProfile, updateProfile, getExpertProfile, updateExpertProfile } from '../../services/user';
import { timezones } from './timezones';
import { Link } from 'react-router-dom';
import ToastModal from '../../ToastModal';
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import { industries } from "../Login/industries"


function EditProfile() {
    const [userDetails, setUserDetails] = useState({ profileImage: "" });
    const [image, setImage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const userType = sessionStorage.getItem('userType');
const [alertSuccess, setAlertSuccess] = useState(false);
const [alertMsg, setAlertMsg] = useState(false);
    const getUserProfileDetails = () => {
        getUserProfile().then((res) => {
            setUserDetails(res.data.data)
        })
    }
    const getExpertProfileDetails = () => {
        getExpertProfile().then((res) => {
            setUserDetails(res.data.data)
        })
    }
    useEffect(() => {
        if (userType === 'U' || userType === 'EU') {
            getUserProfileDetails()
        } else {
            getExpertProfileDetails();
        }
    }, [])
    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }
    const handleUserSubmit = async (e) => {
        e.preventDefault();
        console.log(userDetails.firstname);
        try {
            setAlertMsg(true);
            const res = await updateProfile(
                userDetails
            );
            if (res.data.status) {
                setShowToast(true);
                setAlertMsg(false);
                window.setTimeout(function () {
                    setShowToast(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
            setAlertMsg(false);
        }
        
    }
    const handleExpertSubmit = async (e) => {
        e.preventDefault();
        try {
            setAlertMsg(true);
            const res = await updateExpertProfile(
                userDetails
            );
            if (res.data.status) {
                setShowToast(true);
                setAlertMsg(false);
                window.setTimeout(function () {
                    setShowToast(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
            setAlertMsg(false);
        }
        
    }
    
    
    const handleImage = (e) => {
        // console.log(e.target.files[0],"======e")
        const file = e.target.files[0]
        let reader = new FileReader();
        // console.log("next");

        reader.onload = function () {
            const base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");

            const imageBase64Stringsep = base64String;
            setUserDetails({ ...userDetails, [e.target.name]: reader.result })
            // alert(imageBase64Stringsep);
            setImage(reader.result)
            // console.log(reader.result, "====base64 string");
        }
        reader.readAsDataURL(file);
    }
    // console.log(userDetails, image, "====userDetails");
    return (
        <>
            <div id="kt_app_toolbar" class=" py-3 py-lg-6 ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Profile
                    </h1>

                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                        <li class="breadcrumb-item text-muted">
                            <a href="/" class="text-muted text-hover-primary">
                                Home                            </a>
                        </li>

                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-500 w-5px h-2px"></span>
                        </li>

                        <li class="breadcrumb-item text-muted">
                            Profile                                            </li>
                    </ul>

                </div>
                {showToast && <ToastModal show={showToast} message={"Profile Updated Successfully!"} />}

                {/*<Alert className="position-absolute top-0 end-0 p-3 mt-100 mr-20" show={alertSuccess} variant="success">
                            Profile Updated
    </Alert>*/}
                <div class="card mt-5 mb-80">

                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

                        <div class="card-title m-0 p-4">
                            <h3 class="fw-bold m-0">Profile Details</h3>
                        </div>

                    </div>

                    <div id="kt_account_settings_profile_details" class="collapse show">

                        {alertMsg ?
                            <SweetAlert
                                title=""
                                timeout={10000}
                                showConfirm={false}
                                style={{ width: "200px", height: "100px" }}
                            > <img src={loading} width="50px" height="50px" />
                            </SweetAlert>
                            : <></>}
                        <form id="kt_account_profile_details_form" class="form" onSubmit={(userType === 'U' || userType === 'EU') ? handleUserSubmit : handleExpertSubmit}>

                            <div class="card-body border-top p-9">

                                <div class="row mb-6">

                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Profile Photo</label>
                                    <div class="col-lg-8">

                                        <div class="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${userDetails.profileImage})` }}>

                                            <div class="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${userDetails.profileImage})` }}></div>

                                            <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change Photo">
                                                <i class="fa-solid fa-pencil"><span class="path1"></span><span class="path2"></span></i>

                                                <input type="file" name="profileImage" accept=".png, .jpg, .jpeg" onChange={handleImage} />
                                                <input type="hidden" name="avatar_remove" />

                                            </label>

                                        </div>

                                        <div class="form-text">Allowed file types:  png, jpg, jpeg.</div>
                                    </div>

                                </div>
                                <div class="row mb-6">

                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                                    <div class="col-lg-8">

                                        <div class="row">

                                            <div class="col-lg-6 fv-row">
                                                <input type="text" name="firstname" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" value={userDetails?.firstname} onChange={handleChange} />
                                            </div>
                                            <div class="col-lg-6 fv-row">
                                                <input type="text" name="lastname" class="form-control form-control-lg form-control-solid" placeholder="Last name" value={userDetails?.lastname} onChange={handleChange} />
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>

                                    <div class="col-lg-8 fv-row">
                                        <input type="text" name="email" class="form-control form-control-lg form-control-solid" placeholder="Company name" value={userDetails?.email} disabled />
                                    </div>

                                </div>
                           

                                <div class="row mb-6">

                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">Time Zone</label>

                                    <div class="col-lg-8 fv-row">
                                        <select name="timeZone" aria-label="Select a Timezone" data-control="select2" data-placeholder="Select a timezone.." class="form-select form-select-solid form-select-lg" value={userDetails?.timeZone} onChange={handleChange}>
                                            <option value="">Select</option>
                                            {timezones.map((data) => {
                                                return (
                                                    <option value={data.value}>{data.title}</option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                </div>

                                {userType === 'E' && <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Company</label>

                                    <div class="col-lg-8 fv-row">
                                        <input type="text" name="companyName" class="form-control form-control-lg form-control-solid" placeholder="Company name" value={userDetails?.companyName} onChange={handleChange}/>
                                    </div>

                                </div>}

                                {userType === 'E' && <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Industry Type</label>
                                
                                    <div class="col-lg-8 fv-row">
                                        <select name="industryType" aria-label="Select Industry Type" data-control="select2" data-placeholder="Select industry type..." class="form-select form-select-solid form-select-lg" value={userDetails?.industryType} onChange={handleChange}>
                                            <option value="">Select</option>
                                            {industries.map((data) => {
                                                return (
                                                    <option value={data.value}>{data.title}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                 

                                </div>}

                                {userType === 'E' && <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Head Count</label>

                                    <div class="col-lg-8 fv-row">
                                        <input type="text" name="headCount" class="form-control form-control-lg form-control-solid" placeholder="(1-10),(11-20),..." value={userDetails?.headCount} onChange={handleChange}/>
                                    </div>

                                </div>}

                                {userType === 'E' && <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">About Company</label>

                                    <div class="col-lg-8 fv-row">
                                        <textarea rows={3} type="text" name="about" class="form-control form-control-lg form-control-solid" placeholder="About Your Company" value={userDetails?.about} onChange={handleChange}/>
                                    </div>

                                </div>}

                            </div>

                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                <Link to="/profile"><button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button></Link>
                                <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default EditProfile;
