import React, { useState, useEffect } from 'react';
import { getTicketsList } from '../../../services/support';
import moment from 'moment';
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { _ } from "gridjs-react";

function UserSupportTable(recall) {
    const [userData, setUserData] = useState([]);
    const status = { "O": "Open", "C": "Closed" }
    // Row Data: The data to be displayed.
    const userType = sessionStorage.getItem('userType');


    const getTickets = async () => {
        try {
            const response = await getTicketsList();
            setUserData(response.data.data);
        } catch (error) {
            setUserData([]);
            console.error(error);
        }
    };
    useEffect(() => {
        if (recall) {
            getTickets();
        }
    }, [recall])
   
    useEffect(() => {
        getTickets();
        
    }, [])
   
   
    
    

    return (
        <>
        {/* <div ref={wrapperRef} /> */}
        <div className="col">

<div className="card card-flush h-md-100">
    <div className="card-header pt-7 ps-8 pb-30">
    <h1 className='text-center'>Support List</h1>
    {userType==="E"?
        <Grid 
        data={userData.map(x => [x.name,
            x.subject, x.priority,
            status[x.status],
            moment(x.updatedTime).format("DD-MM-YYYY HH:mm"),
            _(<a href={"/support/view/" + x.supportId}><i className="fa-solid fa-eye fs-4 text-gray-600 ps-1" ></i></a>)
          ])}
        columns={["Name","Subject", "Priority","Status", "Updated Time", "View"]}
        pagination={{
          limit: 10
        }}
        sort={{
          multiColumn: false
        }}
        search
      
      /> :
      <Grid 
      data={userData.map(x => [
          x.subject, x.priority,
          status[x.status],
          moment(x.updatedTime).format("DD-MM-YYYY HH:mm"),
          _(<a href={"/support/view/" + x.supportId}><i className="fa-solid fa-eye fs-4 text-gray-600 ps-1" ></i></a>)
        ])}
      columns={["Subject", "Priority","Status", "Updated Time", "View"]}
      pagination={{
        limit: 10
      }}
      sort={{
        multiColumn: false
      }}
      search
    
    />}
      </div>
      </div>
      </div>
           
        </>
    )
}
export default UserSupportTable;