import React, { useState, useEffect } from 'react';
import getExpertUserList from '../../../services/expertuser';
import UserTable from './UserTable';
import AddUser from './AdduserModal';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';


function User() {
    const [showAddUser, setShowAddUser] = useState(false);
    const [recall, setRecall] =useState(false);
    const addUser = () => {
        setShowAddUser(!showAddUser)
    }
    return (
        <>
            <div className='d-flex flex-stack'>
                <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            Users
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">Users</li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <Link to="/expert/users/add" class="btn btn-sm fw-bold btn-primary">Add User</Link>
                </div>
            </div>
            <Col>
                <UserTable recall={recall}/>
            </Col>
        </>
    )
}
export default User;