import React, { useState, useEffect } from 'react';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link, useParams } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAssessmentProgressList,getAssessmentById,getAssessmentByName } from '../../services/userassessment';

function Assessment(props) {
  const params = useParams();
  const [assessmentList, setAssessmentList] = useState();
  const [left, setLeft] = React.useState([]);
  const [assessmentId, setAssessmentId] = useState();
  const [seoName, setSeoName] = useState();
  const [name, setName] = useState();
  const [desc, setDesc] = useState();
  const [status, setStatus] = useState();
  const [instruction, setInstruction] = useState();
  const [alertMsg, setAlertMsg] = useState(false);

  useEffect(() => {
    setSeoName(params.seoName);
   // getByName(params.seoName);
    getById(params.assessmentId)
  }, []);

  const getById = async (assessmentId) => {
    setAlertMsg(true);
    try {
      const response = await getAssessmentById(assessmentId);
      setAlertMsg(false);
      if (response.data.data != null) {
        setAssessmentId(response.data.data.assessmentId);
        setName(response.data.data.name);
        setDesc(response.data.data.description);
        setInstruction(response.data.data.instruction);
        setStatus(response.data.data.status);
        sessionStorage.setItem('assessmentId', response.data.data.assessmentId);
      }
    } catch (error) {
      setAlertMsg(false);
      console.error(error);
    }
  };

  const startAssessment = async () => {
    window.location.replace("/assessments/" + seoName + "/quiz");
  };


  return (
    <>
         <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/assessments" className="btn btn-sm fw-bold btn-secondary">Back</Link>
                        </div>
                    </div>
                 
                </div>
            </div>
            {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
                <div id="kt_app_content_container" className="app-container  container-xxl ">
                <div className="card card-flush h-md-100">
                        <div className="card-header pt-7 ps-8" style={{marginLeft:"30px"}} >
                  <div className="row g-5 g-xl-8">
                    {name === null ?
                      <div className="col-xl-12">
                        <h2 className="text-center mt-20 mb-20">No Assessments</h2>
                      </div>
                      :
                      <div className="col-xl-12">
                        <h2 className="text-center">{name}</h2>
                        <div className="card-body py-3 mt-20">
                          <div className="table-responsive bg-white px-lg-20 px-0">
                            <h3 className="card-label fw-bold text-gray-800">Description</h3>
                            <div dangerouslySetInnerHTML={{ __html: desc }}></div><br /><br />
                            <h3 className="card-label fw-bold text-gray-800">Instruction</h3>
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div><br /><br />
                          </div>
                          <div className="d-grid mb-10 mt-10 px-lg-20 px-0">
                            {status === "C" ?
                              <></>
                              :
                              <button type="submit" onClick={() => startAssessment()} className="btn btn-primary col-3">
                                <span className="indicator-label">Start Assessment</span>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    }

                  </div>
                  </div>
                  </div>
                </div>
         
    </>
  );
}

export default Assessment;