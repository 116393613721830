import ExpertDashboard from "./DashBoard";
import DashboardTable from "./DashboardTable";
import { Link } from 'react-router-dom';
import "./Overview.css";
import UserDashboard from "./UserDashBoard";

function ExpertOverview() {
    const userType = sessionStorage.getItem('userType')
    return (
        <>
            <div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">

                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            Dashboard
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">
                                    Home                            </Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>

                            <li class="breadcrumb-item text-muted">
                                Dashboard                                          </li>

                        </ul>
                    </div>

            </div>

           {userType==='E' && <ExpertDashboard />}
           {userType==='U' && <UserDashboard />}
           {userType==='EU' && <UserDashboard />}
           <DashboardTable />

        </>
    );
}

export default ExpertOverview;