import axiosInterceptor from '../helpers/axiosInterceptor';
import { CHECKOUT_SESSION,EXPERT_CHECKOUT_SESSION } from "../Constants";

function checkoutSession(packageId,couponId) {
      return axiosInterceptor.post(CHECKOUT_SESSION,{packageId : packageId,couponId : couponId});
}

function expertCheckoutSession(packageId,licenses,couponId) {
      return axiosInterceptor.post(EXPERT_CHECKOUT_SESSION,{packageId : packageId,licenseCount : licenses,couponId : couponId});
}

export  {checkoutSession,expertCheckoutSession};