import React, { useState, useEffect } from 'react';
import { expertSaveCompany } from '../../services/user';
import { industries } from './industries';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import logo from "../../assets/images/resetlogo.png";
import './Login.css';


function ExpertOnboarding() {
const [alertMsg, setAlertMsg] = useState(false);
const [alertError, setAlertError] = useState(false);
const [errMsg, setErrMsg] = useState();
const [errComapnyName, setErrCompanyName] = useState(false);
const [errIndustryType, setErrIndustryType] = useState(false);
const [errHeadCount, setErrHeadCount] = useState(false);
const [industryType, setIndustryType] = useState("");
const [headCount, setHeadCount] = useState("");
const [companyName, setCompanyName] = useState("");
const [about, setAbout] = useState("");

 var companyNameInp, industryTypeInp, headCountInp;

 useEffect(() => {
    companyNameInp = document.getElementById("companyname-input");
    industryTypeInp = document.getElementById("industry-input");
    headCountInp = document.getElementById("headcount-input");
});

const onChangeIndustryType = (val) => {
    if (errIndustryType == true) {
        industryTypeInp.classList.remove("error-input-border");
        setErrIndustryType(false);
    }
    setIndustryType(val);
}
const onChangeHeadCount = (val) => {
    if (errHeadCount == true) {
        headCountInp.classList.remove("error-input-border");
        setErrHeadCount(false);
    }
    setHeadCount(val);
}
const onChangeCompanyName = (val) => {
    if (errComapnyName == true) {
        companyNameInp.classList.remove("error-input-border");
        setErrCompanyName(false);
    }
    setCompanyName(val);
}
const onChangeAbout = (val) => {
    setAbout(val);
}

    const validateFields = () => {
         if (companyName == "") {
            setErrCompanyName(true);
            companyNameInp.classList.add("error-input-border");
            return false;
        }
        if (industryType == "") {
            setErrIndustryType(true);
            industryTypeInp.classList.add("error-input-border");
            return false;
        }
        if (headCount == "") {
            setErrHeadCount(true);
            headCountInp.classList.add("error-input-border");
            return false;
        }
        return true;
    }

   
    const handleExpertSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
        try {
            setAlertMsg(true);
            const res = await expertSaveCompany(
                { companyName, industryType, headCount,about }
            );
            if (res.data.status) {
                setAlertMsg(false);
                setAlertError(false);
                sessionStorage.setItem('userStatus', "A");
                window.location.href = '/overview';
            }
        } catch (error) {
            setErrMsg("Internal Server Error");
            setAlertMsg(false);
            setAlertError(true);
        }
    }
        
    }

    const goToLogin = async () => {
        sessionStorage.clear();
        window.location.href = '/login';
    };
    
   
    return (
        <>
            <div id="kt_app_toolbar" class="py-3 py-lg-6 ">
            <div className="text-center mb-30">
            <img src={logo} title="logo" alt="logo" className="logo-default" />
            </div>
            <div className="text-center mb-30">
             <h1>To Proceed to the Portal, Please submit below details</h1>
             </div>
                <div class="card mt-5 mb-80 m-30">

                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

                        <div class="card-title m-0 p-4">
                            <h3 class="fw-bold m-0">Company Details</h3>
                        </div>

                    </div>

                    <div id="kt_account_settings_profile_details" class="collapse show">

                        {alertMsg ?
                            <SweetAlert
                                title=""
                                timeout={10000}
                                showConfirm={false}
                                style={{ width: "200px", height: "100px" }}
                            > <img src={loading} width="50px" height="50px" />
                            </SweetAlert>
                            : <></>}
                        <form id="kt_account_profile_details_form" class="form" onSubmit={handleExpertSubmit} style={{paddingBottom:"50px"}}>

                            <div class="card-body border-top p-9" >
                               <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Company</label>

                                    <div class="col-lg-8 fv-row">
                                        <input id="companyname-input" type="text" name="companyName" class="form-control form-control-lg form-control-solid" placeholder="Company name" 
                                        value={companyName}  onChange={(e) => onChangeCompanyName(e.target.value)} />
                                        <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errComapnyName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Company Name.</small></span> : null}
                                    </div>

                                </div>

                                <div class="row mb-6">

                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Industry Type</label>

                                    <div class="col-lg-8 fv-row">
                                        <select id="industry-input" name="industryType" aria-label="Select Industry" data-control="select2" data-placeholder="Select industry" class="form-select form-select-solid form-select-lg" 
                                        value={industryType}  onChange={(e) => onChangeIndustryType(e.target.value)} >
                                            <option value="">Select</option>
                                            {industries.map((data) => {
                                                return (
                                                    <option value={data.value}>{data.title}</option>
                                                )
                                            })}
                                        </select>
                                        <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errIndustryType == true ? <span className="input-error-text ml-1rem" ><small>Please Select Industry Type.</small></span> : null}
                                    </div>

                                </div>
                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">Head Count</label>

                                    <div class="col-lg-8 fv-row">
                                        <input id="headcount-input" type="text" name="headCount" class="form-control form-control-lg form-control-solid" placeholder="(1-10),(11-20),..."
                                         value={headCount}  onChange={(e) => onChangeHeadCount(e.target.value)}/>
                                        <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errHeadCount == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Head Count.</small></span> : null}
                                    </div>

                                </div>

                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label fw-semibold fs-6">About</label>

                                    <div class="col-lg-8 fv-row">
                                        <textarea rows={3} name="about" class="form-control form-control-lg form-control-solid" placeholder="About Your Company" 
                                        value={about}  onChange={(e) => onChangeAbout(e.target.value)}/>
                                    </div>

                                </div>

                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Submit</button>
                                <button type="reset" class="btn btn-light btn-active-light-primary me-2 ml-30" onClick={goToLogin}>Cancel</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ExpertOnboarding;
