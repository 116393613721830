import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AssessmentToolBar(props) {
    const [pageTitle, setPageTitle] = useState();
    const [isPurchase, setIsPurchase] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [userType, setUserType] = useState();

    useEffect(() => {
         setUserType(sessionStorage.getItem('userType'));
        setPageTitle(props.title);
        if (props.isPurchase) {
            setIsPurchase(true);
        }
        if (props.continueStatus) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    });

    const payNow = e => {
        props.status("Y");
    };

    const purchase = () => {
        props.page("Themes");
    };

    const continueBtn = () => {
        if (pageTitle == "Themes") {
            props.page("PackageList");
        } else if (pageTitle == "Packages") {
            props.page("PackageSummary");
        }
    };

    const backBtn = () => {
        if (pageTitle === "Themes") {
            props.page("Packages");
        } else if (pageTitle === "Packages") {
            props.page("Themes");
        } else if (pageTitle === "Invoice") {
            props.page("PackageList");
        }
    };

    return (
        <>
            <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            {pageTitle}
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/" className="text-muted text-hover-primary">
                                    Home                            </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>

                            <li className="breadcrumb-item text-muted">
                                {pageTitle}                                            </li>

                        </ul>
                    </div>
                    {userType === 'U' ? <>
                    {isPurchase === false ?
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button onClick={backBtn} className="btn btn-sm fw-bold btn-secondary">
                                <i className="fas fa-angle-left"><span className="path1"></span><span className="path2"></span></i> Back
                            </button>
                            {pageTitle === "Invoice" ?
                                <button onClick={payNow} className="btn btn-sm fw-bold btn-primary">
                                    Pay Now  <i className="fas fa-angle-right"><span className="path1"></span><span className="path2"></span></i>
                                </button>
                                :
                                <button onClick={continueBtn} disabled={isButtonDisabled} className="btn btn-sm fw-bold btn-primary">
                                    Continue  <i className="fas fa-angle-right"><span className="path1"></span><span className="path2"></span></i>
                                </button>
                            }
                        </div>
                        :
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button onClick={purchase} className="btn btn-sm fw-bold btn-primary">Purchase</button>
                        </div>
                    }</>
                    :""}
                </div>
            </div>
        </>
    );
}

export default AssessmentToolBar;