import React, { useState, useEffect } from 'react';
import { getPackageList } from '../../services/userpackage';
import apiToken from '../../apiToken';
import { Link, useParams } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import PackagePrice from '../../PackagePrice';
import { Tooltip,OverlayTrigger,Button,Popover } from 'react-bootstrap';
import { withTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';


function Packages(props) {
  const [packageList, setPackageList] = useState();
  const [left, setLeft] = React.useState([]);
  const [continueStatus, setContinueStatus] = useState(false);
  useEffect(() => {
    getPackages();
  }, []);
  const getPackages = async () => {
    try {
      const response = await getPackageList(props.themeId);
     // console.log(response.data.data);
      setPackageList(response.data.data);
    } catch (error) {
      setPackageList(null);
      console.error(error);
    }
  };

  const getPage = (page) => {
    props.page(page);
  };

  const selectPackage = (val) => {
    setContinueStatus(true);
    props.packageId(val);
  }

  const UpdatingPopover = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
      useEffect(() => {
        console.log('updating!');
        popper.scheduleUpdate();
      }, [children, popper]);
  
      return (
        <Popover className="tooltip" ref={ref} body {...props}>
          {children}
        </Popover>
      );
    },
  );

  return (
    <>
       
              <AssessmentToolBar title={"Packages"} page={getPage}
                isPurchase={false} continueStatus={continueStatus} />
              <div id="kt_app_content" className="app-content  flex-column-fluid" >

                <div id="kt_app_content_container" className="container-xxl ">
                  <h3 className="card-title align-items-start flex-column mb-10">
                    <span className="card-label">Select Package</span>
                  </h3>
                 
                  <div className="row row-cols-lg-4  row-cols-1 row-cols-sm-2 row-cols-md-3 g-5 g-xl-8">
                    {(packageList ? packageList : left).map((value) => (<>
                      {value.purchased == true ? <>
                        <OverlayTrigger
                       placement="bottom"
                       overlay={
                        <UpdatingPopover className="tooltip" id="popover-contained"><h6 style={{color:'white'}}> <div dangerouslySetInnerHTML={{ __html: value.description }}></div></h6></UpdatingPopover>} >
                        <div className="col">
                          <div className="card card-1 inputGroup mb-3">
                            <label className="form-check-label"
                              style={{
                                backgroundImage: "url(" + value.packageImageUrl + ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }} ></label>
                          </div>
                          <h2 className="d-flex justify-content-between px-3">{value.packageName}<span>Purchased</span></h2>
                        </div>
                        </OverlayTrigger>

                      </> :
                       <OverlayTrigger
                       placement="bottom"
                       overlay={
                        <UpdatingPopover className="tooltip" id="popover-contained"><h6 style={{color:'white'}}> <div dangerouslySetInnerHTML={{ __html: value.description }}></div></h6></UpdatingPopover>} >
                        <div className="col">
                          <div className="card card-1 inputGroup mb-3">
                            <input className="form-check-input" value={value.packageId} onChange={(event) => selectPackage(event.target.value)}
                              type="radio" name="flexRadioDefault" id={value.packageId} />
                            <label className="form-check-label"
                              style={{
                                backgroundImage: "url(" + value.packageImageUrl + ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }} htmlFor={value.packageId}></label>
                          </div>
                          <h2 className="d-flex justify-content-between px-3">{value.packageName}
                          {value.isFree == 'Y' ? <>
                          <span>Free</span>
                          </>:
                          <PackagePrice price={value.price}/>}</h2>
                        </div>
                        </OverlayTrigger>
                      }
                    </>)
                    )}
                  </div>

                </div>
              </div>

     
    </>
  );
}

export default Packages;