import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { updatePassword } from '../../../services/login';
import Hide from "../../../assets/images/hide.svg";
import Show from "../../../assets/images/show.svg";

function ChangePassword({show, handleCloseForm, userId}) {
    const [userDetails, setUserDetails] = useState({ newPassword: "", confirmPassword: ""});
    const [errors, setErrors] = useState({ newPassword: false, confirmPassword: false, notSame: false });
    const [showPswd, setShowPswd] = useState({ new : false, confirm : false });

    const showPswdHandler = (type) => {
        let obj = JSON.parse(JSON.stringify(showPswd));
        let value = obj[type];
        obj[type] = !value;
        setShowPswd(obj);
    }


    useEffect(() => {
        // getDetails();
    }, [])
    const handleUserDataChange = (e) => {
        setErrors({ ...errors, [e.target.name]: false, notSame: false })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }
    
    const checkErrors = () => {
        const { newPassword, confirmPassword } = userDetails
        if (!newPassword) {
            setErrors({ ...errors, newPassword: true })
            return true;
        }
        if (!confirmPassword) {
            setErrors({ ...errors, confirmPassword: true })
            return true;
        }
        if (newPassword != confirmPassword) {
            setErrors({ ...errors, notSame: true })
            return true;
        }
        return false;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const { confirmPassword } = userDetails;
        if(!checkErrors()) {
            updatePassword( userId, confirmPassword).then((res)=>{
                if(res.data) {
                    handleCloseForm();
                }
            })
        }
      
    }
    return (
        <>
            <Modal show={show} onHide={handleCloseForm}>
                <Modal.Header closeButton>
                    <Modal.Title>
                            <h1 className="text-gray-900 fw-bolder">
                                Change Password
                            </h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
                        
                        <div className="fv-row mb-8">
                            <input type={showPswd['new'] ? "text" : "password"} onChange={handleUserDataChange} placeholder="Password" name="newPassword" autoComplete="off" className="form-control bg-transparent" value={userDetails.newPassword}/>
                            <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler('new') }}>
                        {showPswd['new']===true ? <img src={Hide} /> : <img src={Show} />}
                    </div>
                            {errors.newPassword && <div class="invalid-error input-error-text pt-2">
                                Please enter Password.
                            </div>}

                        </div>

                        <div className="fv-row mb-8">
                            <input type={showPswd['confirm'] ? "text" : "password"} onChange={handleUserDataChange} placeholder="Confirm Password" name="confirmPassword" autoComplete="off" className="form-control bg-transparent" value={userDetails.confirmPassword}/>
                            <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler('confirm') }}>
                        {showPswd['confirm']===true ? <img src={Hide} /> : <img src={Show} />}
                    </div>
                            {errors.confirmPassword && <div class="invalid-error input-error-text pt-2">
                                Please enter Confirm Password.
                            </div>}
                            {errors.notSame && <div class="invalid-error input-error-text pt-2">
                                Password and confirm password are not same.
                            </div>}
                        </div>

                        <div className="d-grid mb-10">
                            <button type="submit" id="kt_sign_up_submit" className="btn btn-primary">

                                <span className="indicator-label">
                                    {"Update Password"}</span>

                                <span className="indicator-progress">
                                    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChangePassword;

