import { useEffect, useState } from "react";
import { getDashboardDetails } from "../../../services/expertdashboard";

function ExpertDashboard() {
    const [details, setDetails] = useState({ expertPackageCountsDTO: {}, expertUserCountsDTO: {}, expertSupportCountsDTO: {} });
    const getDetails = () => {
        getDashboardDetails().then((res) => {
            if (res.data.data)
                setDetails(res.data.data)
        })
    }
    useEffect(() => {
        getDetails();
    }, [])
    return (
        <div class="row g-5 gx-xl-10">

            <div class="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">

                <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end  mb-5 mb-xl-10 overflow-hidden h-100"
                    style={{ backgroundColor: "#F1416C", backgroundImage: "url('assets/users.png')" }}>

                    <div class="card-header pt-5">

                        <div class="card-title d-flex flex-column">

                            <span class="fs-5hx fw-bold text-white me-2 lh-1 ls-n2">{Object.keys(details.expertUserCountsDTO).length > 0 ? details.expertUserCountsDTO.totalUsers : 0}</span>
                            <span class="text-white opacity-75 pt-1 fw-semibold fs-6">Users</span>

                        </div>

                    </div>
                    <div class="card-body d-flex align-items-center  py-0 ">

                        <div class="d-flex flex-column content-justify-center flex-row-fluid">

                            <div class="d-flex fw-semibold align-items-center">

                                <div class="bullet w-8px h-3px rounded-2 bg-success me-3"></div>

                                <div class="text-white flex-grow-1 me-4">Active</div>

                                <div class="fw-bolder text-white text-xxl-end">{details.expertUserCountsDTO ? details.expertUserCountsDTO.activeUsers : 0}</div>

                            </div>

                            <div class="d-flex fw-semibold align-items-center my-3">

                                <div class="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>

                                <div class="text-white flex-grow-1 me-4">Inactive</div>

                                <div class="fw-bolder text-white text-xxl-end">{details.expertUserCountsDTO ? details.expertUserCountsDTO.inactiveUsers : 0}</div>

                            </div>

                            <div class="d-flex fw-semibold align-items-center">

                                <div class="bullet w-8px h-3px rounded-2 me-3" style={{ backgroundColor: "#E4E6EF" }}></div>

                                <div class="text-white flex-grow-1 me-4">Pending</div>

                                <div class=" fw-bolder text-white text-xxl-end">{details.expertUserCountsDTO ? details.expertUserCountsDTO.pendingUsers : 0}</div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">

                <div class="card card-flush h-md-50 mb-5 mb-xl-10 bg-primary-subtle overflow-hidden d-none">

                    <div class="card-header pt-5">

                        <div class="card-title d-flex flex-column w-100">

                            <div class="d-flex justify-content-between w-100">
                                <span class="fs-5hx fw-bold text-gray-900 me-2 lh-1 ls-n2">4</span>
                                <span class="badge badge-light-primary flex-shrink-0 align-self-center py-3 px-4 fs-7">In Process</span>
                            </div>

                            <span class="text-gray-500 pt-1 fw-semibold fs-6">Assigned</span>

                        </div>

                    </div>

                    <div class="card-body d-flex flex-column justify-content-center pe-0 pt-0">

                        <span class="fs-2hx fw-bolder text-white d-block mb-2 d-flex align-items-center">
                            <i class="ki-duotone ki-code text-gray-900 fs-2tx me-3"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                            35
                        </span>
                        <span class="fs-6 fw-bolder text-white d-block mb-2">Today’s Assignment</span>

                    </div>

                </div>

                <div class="card card-flush mb-5 mb-xl-10 overflow-hidden h-100" style={{ backgroundColor: "#7239EA", backgroundImage: "url('/packages.svg')" }}>

                    <div class="card-header pt-5">

                        <div class="card-title d-flex flex-column">

                            <div class="d-flex align-items-center">

                                <span class="fs-5hx fw-bold text-white me-2 lh-1 ls-n2">{details.expertPackageCountsDTO.totalPackages}</span>

                            </div>

                            <span class="text-white pt-1 fw-semibold fs-6">Packages</span>

                        </div>

                    </div>

                    <div class="card-body d-flex flex-wrap align-items-center py-0">

                        <div class="d-flex flex-column content-justify-center flex-row-fluid">

                            <div class="d-flex fw-semibold align-items-center">

                                <div class="bullet w-8px h-3px rounded-2 bg-success me-3"></div>

                                <div class="text-white flex-grow-1 me-4">Purchased</div>

                                <div class="fw-bolder text-white text-xxl-end">{details.expertPackageCountsDTO.purchased}</div>

                            </div>

                            <div class="d-flex fw-semibold align-items-center my-3">

                                <div class="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>

                                <div class="text-white flex-grow-1 me-4">Assigned</div>

                                <div class="fw-bolder text-white text-xxl-end">{details.expertPackageCountsDTO.assigned}</div>

                            </div>

                            <div class="d-flex fw-semibold align-items-center">

                                <div class="bullet w-8px h-3px rounded-2 me-3" style={{ backgroundColor: "#E4E6EF" }}></div>

                                <div class="text-white flex-grow-1 me-4">Remaining</div>

                                <div class=" fw-bolder text-white text-xxl-end">{details.expertPackageCountsDTO.remaining}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="card card-flush d-none" style={{ background: "linear-gradient(112.14deg, #FF8A00 0%, #E96922 100%)" }}>

                    <div class="card-header pt-5">

                        <div class="card-title d-flex flex-column">

                            <span class="fs-5hx fw-bold text-white me-2 lh-1 ls-n2">4</span>
                            <span class="text-white opacity-75 pt-1 fw-semibold fs-6">Remaining</span>

                        </div>

                    </div>

                    <div class="card-body pt-5 bg-dark bg-opacity-20">
                        <div class="d-flex flex-stack">
                            <a href="#" class="text-white fw-semibold fs-6 me-2">Avg. Client Rating</a>

                            <button type="button" class="btn btn-icon btn-sm h-auto btn-color-white btn-active-color-primary justify-content-end">
                                <i class="ki-duotone ki-exit-right-corner fs-2"><span class="path1"></span><span class="path2"></span></i>                </button>

                        </div>

                        <div class="separator separator-dashed my-3"></div>
                        <div class="d-flex flex-stack">

                            <a href="#" class="text-white fw-semibold fs-6 me-2">Assigned</a>
                            <button type="button" class="btn btn-icon btn-sm h-auto btn-color-white btn-active-color-primary justify-content-end">
                                <i class="ki-duotone ki-exit-right-corner fs-2"><span class="path1"></span><span class="path2"></span></i>                </button>

                        </div>
                        <div class="separator separator-dashed my-3"></div>
                        <div class="d-flex flex-stack">

                            <a href="#" class="text-white fw-semibold fs-6 me-2">Purchased</a>
                            <button type="button" class="btn btn-icon btn-sm h-auto btn-color-white btn-active-color-primary justify-content-end">
                                <i class="ki-duotone ki-exit-right-corner fs-2"><span class="path1"></span><span class="path2"></span></i>                </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">

                <div class="card card-flush mb-5 mb-xl-10 overflow-hidden h-100 bg-warning" style={{ backgroundImage: "url('/support.svg')" }}>

                    <div class="card-header pt-5">

                        <div class="card-title d-flex flex-column w-100">

                            <div class="d-flex justify-content-between w-100">
                                <span class="fs-5hx fw-bold text-white me-2 lh-1 ls-n2">{details.expertSupportCountsDTO.totalSupports}</span>
                            </div>
                            <span class="text-white pt-1 fw-semibold fs-6">Support</span>
                        </div>

                    </div>

                    <div class="card-body d-flex flex-column justify-content-center py-0">

                        <span class="fs-2hx fw-bolder text-white d-block mb-2 d-flex align-items-center">
                            <i class="fa-solid fa-calendar-days fa-lg fs-4 support-calender"></i>
                            {details.expertSupportCountsDTO.currentCount}
                        </span>
                        <span class="fs-6 fw-bolder text-white d-block mb-2">Today’s Queries</span>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default ExpertDashboard;