import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Package.css'
import AssesmentPackageAccordion from './ExpertPackageAccordion';
import { Link } from 'react-router-dom';

function AssesementPackages() {
   
    return (
        <>
            {/* <div className="app-main flex-column flex-row-fluid " id="kt_app_main"> */}
                {/* <div className="d-flex flex-column flex-column-fluid">*/}

                    <div > 
                        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >

                       
                                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                        Packages
                                    </h1>

                                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                                        <li class="breadcrumb-item text-muted">
                                            <Link to="/" class="text-muted text-hover-primary">Home</Link>
                                        </li>

                                        <li class="breadcrumb-item">
                                            <span class="bullet bg-gray-500 w-5px h-2px"></span>
                                        </li>

                                        <li class="breadcrumb-item text-muted">Packages</li>

                                    </ul>
                                </div>

                            

                        </div>

                    </div>
                    <div >
                        <AssesmentPackageAccordion />
                    </div>
                {/* // </div> */}
            {/* </div> */}
        </>
    )
}
export default AssesementPackages;