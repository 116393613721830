import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import '../../App.css';
import { Link } from 'react-router-dom';
import { getUserProfile } from '../../services/user';
import WarningModal from '../Expert/User/WarningModal';
import logo from "../../assets/images/resetlogo.png";

function Header() {
    const [showMenu, setShowMenu] = useState(false);
    const [userType, setUserType] = useState();
    const [menuActive, setMenuActive] = useState(false);
    const [currentPath, setCurrentPath] = useState();
    const [showOptions, setShowOptions] = useState(false);
    const [profileDetails, setProfileDetails] = useState({ profileImage: "" });
    const [showWarning, setShowWarning] = useState(false)
    const navItems = [
        { label: "Overview", userType: 'E', route: '/overview' },
        { label: "Users", userType: 'E', route: '/expert/users', childRoutes: ["/users/editUser/:id", "/users/addUser", "/users/:id", "/users/:name/:id"] },
        { label: "Packages", userType: 'E', route: '/expert/packages' },
        { label: "Support", userType: 'E', route: '/support' },
        { label: "Help", userType: 'E', route: '/faqs' },
        { label: "Overview", userType: 'U', route: '/overview' },
        {
            label: "Packages", userType: 'U', route: '/packages',
            childRoutes: ['/packages/report', '/assessments', '/assessments/:seoName/quiz',
                '/assessments/:seoName/results', '/assessments/:seoName']
        },
        { label: "Support", userType: 'U', route: '/support', childRoutes: ['/usersupport/info/:id'] },
        { label: "Help", userType: 'U', route: '/faqs' },
        { label: "Overview", userType: 'EU', route: '/overview' },
        { label: "Packages", userType: 'EU', route: '/packages' },
        { label: "Support", userType: 'EU', route: '/support', childRoutes: ['/usersupport/info/:id'] },
        { label: "Help", userType: 'EU', route: '/faqs' },

    ]

    const logout = async () => {
        setShowWarning(false);
        sessionStorage.clear();
        window.location.href = '/login';
    };

    const handleWarningModal = () => {
        setShowWarning(false);
    }

    const signout = () => {
        setShowWarning(true);
    }

    function refreshAssessmentPage() {
        window.location.reload(false);
        window.location.href = '/packages';
    }
    const getProfileDetails = async () => {
        try {
            const response = await getUserProfile();
            let userData = response.data;
            setProfileDetails(response.data.data);
            sessionStorage.setItem('userType', userData.data.userType);
            setUserType(userData.data.userType);
          
        } catch (error) {
            console.error(error);
        }
    };
    // console.log(profileDetails,"===profileDetails")
    const location = useLocation().pathname;
    const packageRoutes = ['/packages', '/packages/report', '/assessments', '/assessments/:seoName/quiz',
        '/assessments/:seoName/results', '/assessments/:seoName'];

    const isMatch = packageRoutes.includes(location);

    useEffect(() => {

        // if (!sessionStorage.getItem('currencyCode')) {
             getProfileDetails();
        // }

    }, []);

    const openMenu = () => {
        setShowMenu(true);
        console.log(true);
      };
     
      const closeMenu = () => {
         setShowMenu(false);
       };

    return (
        <>
            {userType &&
                <div id="kt_app_header" className="app-header" >
                      {showMenu ? 
                      <>
                      <div className="side-menu-bg">
        <div className="inner-wrapper">
            <span className="btn-close menu-close-btn" id="btn_sideNavClose" onClick={closeMenu}>
            </span>
           <Link to="/overview" title="Logo" className="logo" onClick={closeMenu}>
                <img src={logo} title="logo" alt="logo" className="logo-default" />
            </Link>
            <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0 justify-content-lg-center" id="kt_app_header_menu" >
                                    {navItems.map((item, index) => {
                                        return (<>
                                            {userType === item.userType ? <>
                                                {userType === 'U' && item.label === "Packages" ?
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" onClick={refreshAssessmentPage}>
                                                        <Link className={isMatch ? "menu-link active" : "menu-link"} onClick={closeMenu}>
                                                            <span className={isMatch ? "menu-title" : "menu-title-span"}>{item.label}</span>
                                                        </Link>
                                                    </div> :
                                                    <>
                                                    {item.label === "Overview" ?
                                                      <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" >
                                                        <Link className={location === "/" || location === "/overview" ?"menu-link active":"menu-link"}  to={item.route} onClick={closeMenu}>
                                                            <span className={location === "/" || location === "/overview" ?"menu-title": "menu-title-span"}>{item.label}</span>
                                                        </Link>
                                                    </div> :
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" >
                                                    <Link className={location === item.route ? "menu-link active" : "menu-link"} to={item.route} onClick={closeMenu}>
                                                        <span className={location === item.route ? "menu-title" : "menu-title-span"}>{item.label}</span>
                                                    </Link>
                                                </div>
                                                  }
                                                    </>
                                                  
                                                }
                                            </> : ""}
                                        </>)
                                    })}

                                </div>
                    </div>
                    </div>
                      </> : ""}


                      <div className="app-container  container-fluid  container-xxl d-flex align-items-stretch justify-content-between " id="kt_app_header_container">
                        <div className="app-navbar-item d-lg-none me-n2 d-flex align-items-center" title="Show header menu" onClick={openMenu}>
                            <div className="w-30px h-30px side-menu-btn" id="kt_app_header_menu_toggle">
                            <i class="fas fa-bars" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">

                            <div className="app-header-menu app-header-mobile-drawer align-items-stretch w-100" >

                                <Link to="/" className="d-flex align-items-center d-none d-lg-block">
                                    <h1 className="app-sidebar-logo text-white fw-normal logo-fs" ><img src={logo} /></h1>
                                    <img alt="Logo" src="assets/media/logos/RESETNavLogo.svg" className="app-sidebar-logo-default d-none" />
                                    <img alt="Logo" src="assets/media/logos/default-small.svg" className="h-20px app-sidebar-logo-minimize d-none" />
                                </Link>

                                <div className="menu  menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0 justify-content-lg-center" id="kt_app_header_menu" >
                                    {navItems.map((item, index) => {
                                        return (<>
                                            {userType === item.userType ? <>
                                                {userType === 'U' && item.label === "Packages" ?
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" onClick={refreshAssessmentPage}>
                                                        <Link className={isMatch ? "menu-link active" : "menu-link"}>
                                                            <span className={isMatch ? "menu-title" : "menu-title-span"}>{item.label}</span>
                                                        </Link>
                                                    </div> :
                                                    <>
                                                    {item.label === "Overview" ?
                                                      <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" >
                                                        <Link className={location === "/" || location === "/overview" ?"menu-link active":"menu-link"}  to={item.route}>
                                                            <span className={location === "/" || location === "/overview" ?"menu-title": "menu-title-span"}>{item.label}</span>
                                                        </Link>
                                                    </div> :
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" >
                                                    <Link className={location === item.route ? "menu-link active" : "menu-link"} to={item.route}>
                                                        <span className={location === item.route ? "menu-title" : "menu-title-span"}>{item.label}</span>
                                                    </Link>
                                                </div>
                                                  }
                                                    </>
                                                  
                                                }
                                            </> : ""}
                                        </>)
                                    })}

                                </div>

                            </div>


                            <div className="mt-5">

                                <div className=" ms-1 ms-md-4" id="kt_header_user_menu_toggle">
                                    <div className="cursor-pointer symbol symbol-35px" onMouseOver={() => setShowOptions(true)}
                                    >
                                        {profileDetails.profileImage ? <img src={profileDetails.profileImage} className="rounded-3" alt="/profile_img.jpg" /> : <img src={"/profile1.png"} className="rounded-3" style={{ backgroundColor: "white" }} alt="/profile_img.jpg" />}
                                    </div>

                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-300px" onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)} style={{ display: showOptions ? "block" : "none", position: "fixed", top: "76px", right: "4%" }}>
                                        <div className="menu-item px-3">
                                            <div className="menu-content d-flex align-items-center px-3">
                                                <div className="symbol symbol-50px me-5">
                                                    {profileDetails.profileImage ? <img alt="Logo" src={profileDetails.profileImage} /> : <img src={"/profile1.png"} className="rounded-3" alt="/profile_img.jpg" />}
                                                </div>

                                                <div className="d-flex flex-column">
                                                    <div className="fw-bold d-flex align-items-center fs-5">
                                                        {profileDetails.firstname} {profileDetails.lastname}
                                                        {/* <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span> */}
                                                    </div>

                                                    <a className="fw-semibold text-muted text-hover-primary fs-7">
                                                        {profileDetails.email}                </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator my-2"></div>

                                        <div className="menu-item px-5">
                                            <a href="/profile" className="menu-link px-5">
                                                My Profile
                                            </a>
                                        </div>

                                        <div className="separator my-2"></div>

                                        <div className="menu-item px-5">
                                            <a onClick={signout} className="menu-link px-5">
                                                Sign Out
                                            </a>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                    {showWarning && <WarningModal show={showWarning} cancel={logout} onHide={handleWarningModal} message={"Do you want to logout?"} />}
                </div>
            }
        </>
    );
}

export default Header;