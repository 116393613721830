import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getThemeList } from '../../../services/userpackage';
import { getUserDetails } from '../../../services/expertuser';
import { Button } from 'react-bootstrap';
import PackagesList from './PackagesList';
import ChangePassword from './ChangePassword';
import { Link } from 'react-router-dom';


function UserView() {
    const { id } = useParams();
    const [showChangepwd, setShowpwd] = useState(false);
    const [details, setDetails] = useState();
    const handleChangepwd = () => {
        setShowpwd(!showChangepwd)
    }
    const getDetails = async () => {
        try {
            const response = await getUserDetails(id);
            setDetails(response.data.data);
            console.log(response.data.data);

        } catch (error) {
            setDetails({});
            console.error(error);
        }
    };
    useEffect(() => {
        getDetails();
    }, [])
    // console.log(details,"details=====")
    return (
        <>
         <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/expert/users" className="btn btn-sm fw-bold btn-secondary">Back</Link>
                        </div>
                    </div>
                  
                </div>
            </div>
            
            {details && <><div class="card mt-5 mb-5 mb-xl-10" id="kt_profile_details_view">
                <div class="card-header cursor-pointer">

                    <div class="card-title m-0 py-4">
                        <h3 class="fw-bold m-0">User Details</h3>
                    </div>


                </div>
                <div class="card-body p-9">
                    <div class="row mb-7">
                        <label class="col-lg-2 fw-bold fs-6 text-gray-800">Name</label>
                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800">{details.firstname} {details.lastname}</span>
                        </div>
                    </div>
                  
                    <div class="row mb-7">
                        <label class="col-lg-2 fw-bold fs-6 text-gray-800">Email</label>
                        <div class="col-lg-8 fv-row">
                            <span class="fw-semibold text-gray-800 fs-6">{details.email}</span>
                        </div>

                    </div>
                    <div class="row mb-7">
                        <label class="col-lg-2 fw-bold fs-6 text-gray-800">Timezone</label>
                        <div class="col-lg-8 fv-row">
                            <span class="fw-semibold text-gray-800 fs-6">{details.timeZone}</span>
                        </div>

                    </div>
                   
                    <Button variant='primary' onClick={handleChangepwd}>Change Password</Button>
                  {showChangepwd && <ChangePassword handleCloseForm={handleChangepwd} show={showChangepwd} userId = {id}/>}
                </div>
            </div>
            {(details.packageDTOs && details.packageDTOs.length>0)&&<div class="card mt-5 mb-80" id="kt_profile_details_view">
                <div class="card-header cursor-pointer">

                    <div class="card-title m-0 py-4">
                        <h3 class="fw-bold m-0">Assigned Packages</h3>
                    </div>


                </div>
                <div class="card-body p-5">
                    {/* <h3>Packages List</h3> */}
                   
                        <PackagesList data={details.packageDTOs} userId={details.userId}/>

                </div>
            </div>}
            </>}
        </>
    )
}
export default UserView;