import React, { useState, useEffect } from 'react';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import { getPurchasedPackages } from '../../services/userpackage';
import { startAssessment } from '../../services/userassessment';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function PackageList(props) {
  const [assessmentList, setAssessmentList] = useState();
  const [left, setLeft] = useState([]);
  const [packageId, setPackageId] = useState();
  const [alertMsg, setAlertMsg] = useState(false);


  useEffect(() => {
    getPackages();
  }, []);
  const getPackages = async () => {
    //setAlertMsg(true);
    try {
      const response = await getPurchasedPackages();
     // console.log(response);
    // setAlertMsg(false);
      setAssessmentList(response.data.data);
    } catch (error) {
    //  setAlertMsg(false);
      setAssessmentList(null);
     // console.error(error);
    }
  };
  const start = async (packageId) => {
    sessionStorage.setItem('packageId', packageId);
    window.location.replace("/assessments");
  };
  const getPage = (page) => {
    props.page(page);
  };

  const report = async (packageId) => {
    sessionStorage.setItem('packageId', packageId);
    window.location.replace("/packages/report");
  };


  return (
    <>
               <AssessmentToolBar title={"Packages"} isPurchase={true} page={getPage} />
               {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
            <div id="kt_app_content_container" className="container-xxl ">
              <div className="row g-5 g-xl-8 mb-80">
                <div className="col-xl-12">
                  <h2 className="text-center">Your Packages</h2>
                  <div className="">
                    <div className="card-body py-3">
                      <div className="table-responsive bg-white px-0">
                        <table className="table table-striped fs-4 border border-dark-subtle packageList" cellPadding="5">
                          <thead className="bg-body-secondary border border-dark-subtle">
                            <tr className="fw-bold">
                              <th className="ps-md-10 ps-5 border-end-0">Package Title</th>
                              <th className="ps-md-10 border-start-0 border-end-0 package">Status</th>
                              <th className="ps-md-10 border-start-0 package">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {(assessmentList ? assessmentList : left).map((key, value) => (
                              <tr>
                                <td className="ps-md-10 ps-5">{key.name}</td>
                                {key.status === 'N' ? (<>
                                  <td>
                                    <span className="fw-normal justify-content-center badge badge-danger fs-5 plr-20">Not Started</span>
                                  </td>
                                  <td><button onClick={() => start(key.packageId)} className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between fs-5 width-50">Start
                                    <i className="icon-xl fas fa-play-circle pl20 fs-5"></i>
                                  </button>
                                  </td>
                                </>) : (<>
                                  {key.status === 'S' ? (<>
                                    <td>
                                      <span className="fw-normal plr-20 justify-content-center badge badge-primary fs-5">In Progress</span>
                                    </td>

                                    <td><button onClick={() => start(key.packageId)} className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between width-50 fs-5">Resume
                                      <i className="icon-xl fas fa-check-circle pl20 fs-5"></i>
                                    </button>
                                    </td>
                                  </>) : (<>
                                    <td >
                                      <span className="fw-normal plr-20 justify-content-center badge badge-success fs-5">Completed</span>
                                    </td>

                                    <td><button onClick={() => report(key.packageId)} className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between width-50 fs-5">Report
                                      <i class="icon-xl fas fa-clipboard-list pl20 fs-5"></i>
                                    </button>
                                    </td>
                                  </>)
                                  }
                                </>)
                                }
                              </tr>
                            )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
         

    </>
  );
}

export default PackageList;