import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
    let tokenInfo = jwtDecode(userToken.token);
        let tokenObj = JSON.parse(tokenInfo.token);
        let userStatus = tokenObj['status'];
        let userId = tokenObj['userId'];
        let userType = tokenObj['userType'];
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userType', userType);
        sessionStorage.setItem('userStatus', userStatus);
        sessionStorage.setItem('jwt', userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}