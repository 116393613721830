import React, { useState, useEffect } from 'react';
import { getAssessmentList } from '../../services/userassessment';
import apiToken from '../../apiToken';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link, useParams } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import { checkoutSession } from '../../services/checkoutsession';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import PackagePrice from '../../PackagePrice';
import { validateUserCoupon } from '../../services/user';

function PackageSummary(props) {
  const [assessmentList, setAssessmentList] = useState();
  const [packageName, setPackageName] = useState();
  const [themeName, setThemeName] = useState();
  const [price, setPrice] = useState();
  const [free, setFree] = useState();
  const [alertMsg, setAlertMsg] = useState(false);
  const [left, setLeft] = React.useState([]);
  const currencyCode = sessionStorage.getItem("currencyCode");
  const [coupon, setCoupon] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  var totalPriceInp,couponInp;
  const [errCoupon, setErrCoupon] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [discount, setDiscount] = useState();
  const [discountStatus, setDiscountStatus] = useState(false);
  const [couponId, setCouponId] = useState(0);
  const [removeButton, setRemoveButton] = useState(false);

  useEffect(() => {
    totalPriceInp = document.getElementById("totalPrice");
    couponInp = document.getElementById("coupon-input");
 });

  useEffect(() => {
    getAssessments();
  }, []);
  const getAssessments = async () => {
    try {
      const response = await getAssessmentList(props.packageId);
      setAssessmentList(response.data.data.assessments);
      setPackageName(response.data.data.packageName);
      setThemeName(response.data.data.themeName);
      setPrice(response.data.data.price);
      setFree(response.data.data.isFree);
    } catch (error) {
      setAssessmentList(null);
      console.error(error);
    }
  };

  const getStatus = async (status) => {
    if (status == "Y") {
      setAlertMsg(true);
      try {
        if(free == "Y"){
          window.location.replace("/payment/"+props.packageId+"/0/success");
        }else{
          if(price-discount <= 0){
            window.location.replace("/payment/"+props.packageId+"/0/success");
          }else{
        const res = await checkoutSession(props.packageId,couponId);
        setAlertMsg(false);
        window.location.replace(res.data.data);
          }
        }
      } catch (error) {
      }
    }
  }

  const getPage = (page) => {
    props.page(page);
  };

  const validateFields = () => {
    let pass = true;
    if (coupon.trim() == "") {
        setErrCoupon(true);
        couponInp.classList.add("error-input-border");
        pass = false;
    }
    return pass;
}
const onChangeCoupon = (val) => {
  if (errCoupon == true) {
      couponInp.classList.remove("error-input-border");
      setErrCoupon(false);
  }
  setCoupon(val);
}

const removeCoupon = async e => {
  setErrMessage(false);
  setCouponId(0);
  setSuccessMessage(false);
  setDiscountStatus(false);
  setRemoveButton(false);
  setCoupon("");
}

const applyCoupon = async e => {
  e.preventDefault();
  if (!validateFields()) {
      return;
  } else {
    try {
      const response = await validateUserCoupon(coupon,props.packageId);
      console.log(response.data.data.status);
      if(!response.data.data.status){
        setErrMessage(true);
        setErrorMsg(response.data.data.message);
        setSuccessMessage(false);
        setDiscountStatus(false);
        setRemoveButton(false);
      }else{
        setErrMessage(false);
        setSuccessMessage(true);
        setRemoveButton(true);
        const type = response.data.data.type;
        if(type === "F"){
          setDiscount(response.data.data.amount);
        }else{
          const per = response.data.data.percentage;
          const discount = (price * per)/100;
          setDiscount(discount);
        }
        setDiscountStatus(true);
        setCouponId(response.data.data.couponId);
      }
    } catch (error) {
     // console.error(error);
    }
  }
}


  return (
    <>
          <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <AssessmentToolBar title={"Invoice"} isPurchase={false} status={getStatus} page={getPage} />
              <div id="kt_app_content" className="app-content  flex-column-fluid" >
                {alertMsg ?
                  <SweetAlert
                    title=""
                    timeout={10000}
                    showConfirm={false}
                    style={{ width: "200px", height: "100px" }}
                  > <img src={loading} width="50px" height="50px" />
                  </SweetAlert>
                  : <></>}
                <div className="container">
                  <div className="row gutters justify-content-center mb-80">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                      <div className="card  border-primary overflow-hidden shadow">
                        <div className="card-body p-0">
                          <div className="invoice-container">
                            <div className="invoice-body">
                              <div className="row gutters">
                                <div className="col-lg-12 col-md-12 col-sm-12 p-10 border-bottom">
                                  <h2>
                                    Hi,
                                  </h2>
                                  <p className="fs-4">
                                    This is the receipt for a payment of <strong> 
                                    {free == 'Y' ? <>
                                    <PackagePrice price={0}/>
                          </>:
                                     <>
                                     {discountStatus ?   <>
                          {price-discount <= 0 ? <PackagePrice price={0}/> :
                          <PackagePrice price={price-discount}/> 
                          }
                          </>  :
                                                   <PackagePrice price={price}/> }</>
                }
                                      </strong>.
                                  </p>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="table-responsive">
                                    <table className="table border-bottom border-gray-200 mt-3 fs-4">
                                      <thead className="border-bottom">
                                        <tr>
                                          <th scope="col" className="text-dark text-uppercase fw-bold ps-5">Description</th>
                                          <th scope="col" className="text-dark text-uppercase fw-bold text-end pe-5">Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="ps-5">{themeName} - {packageName}
                                          </td>
                                          <td className="text-end pe-5">
                                          {free == 'Y' ? <>
                                          <PackagePrice price={0}/>
                          </>:
                           <PackagePrice price={price}/>
              }
              </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div className="mt-5 fs-4">
                                      <div className="d-flex justify-content-end">
                                        <p className="text-muted me-3">Subtotal:</p>
                                        <span>
                                        {free == 'Y' ? <>
                                        <PackagePrice price={0}/>
                          </>:<PackagePrice price={price}/>
}</span>
                                      </div>
                                      {discountStatus ? <div className="d-flex justify-content-end">
                                        <p className="text-muted me-3">Discount:</p>
                                        <span><PackagePrice price={discount}/></span>
                                      </div> : <div className="d-flex justify-content-end">
                                        <p className="text-muted me-3">Discount:</p>
                                        <span><PackagePrice price={0}/></span>
                                      </div>}
                                      <div className="d-flex justify-content-end mt-3">
                                        <h5 className="me-3 fs-4">Total:</h5>
                                        <h5 className="text-success fs-4" id="totalPrice"> 
                                        {free == 'Y' ? <>
                                        <PackagePrice price={0}/>
                          </>:  <>
                          {discountStatus ?  
                          <>
                          {price-discount <= 0 ? <PackagePrice price={0}/> :
                          <PackagePrice price={price-discount}/> 
                          }
                          </>
                           :
                           <PackagePrice price={price}/> }</>
                                    }
                                        </h5>
                                      </div>
                                      {free == 'N' ? <>
                                      <div className="d-flex justify-content-end mt-3">
                                      <h5 className="fs-4 col-md-3 mt-3">Coupon Code</h5>
                                      <input type="text" id="coupon-input" value={coupon} disabled={removeButton ? "disabled" : ""}
                                        onChange={(e) => onChangeCoupon(e.target.value)}
                                        placeholder="Enter Coupon Code" name="coupon" autoComplete="off" className="me-3 form-control bg-transparent" />
                                       <div className='col-md-2'></div>
                                        {removeButton ? 
                                        <button onClick={removeCoupon} type="submit" className="btn btn-primary">Remove</button> : 
                                        <button onClick={applyCoupon} type="submit" className="btn btn-primary">Apply</button>
                                         }
                                      </div>
                                      <div className="d-flex">
                                      <h5 className="fs-4 col-md-3"></h5>
                                      <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errCoupon == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Coupon Code.</small></span> : null}
                                    {errMessage == true ? <span className="input-error-text ml-1rem" ><small>{errorMsg}</small></span> : null}
                                    {successMessage == true ? <span className="input-error-text text-success ml-1rem" ><small>Coupon Applied!</small></span> : null}
                                    </div></> :<></>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            </div>
    </>
  );
}

export default PackageSummary;