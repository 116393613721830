import React, { useState } from 'react';
import './Login.css';
import { loginUser } from '../../services/login';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/custom-1.png";
import banner from "../../assets/images/auth-screens.png";


function RightBanner() {
    return(
      <>
     <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1 left-image login-bg">
        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">          
            <div className="mb-0 mb-lg-12 h-60px h-lg-75px">
            </div>    

            <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={banner} alt=""/>                 

          
        </div>
    </div>
    </>
  )
}

export default RightBanner;
