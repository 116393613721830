import axiosInterceptor from '../helpers/axiosInterceptor';
import { CHANGE_PASSWORD, EXPERT_UPDATE_PASSWORD, GET_TOKEN, LOGIN } from "../Constants";

export function loginUser(credentials) {
 return fetch(LOGIN, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}
export function getToken(request) {
  return fetch(GET_TOKEN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
 }
export function updatePassword(userId, pwd) {
  return axiosInterceptor.post(EXPERT_UPDATE_PASSWORD,
      {
          "password": pwd,
          "userId": userId
        });
}
export function changePassword(newPassword) {
  return axiosInterceptor.post(CHANGE_PASSWORD,
      {
          "newPassword": newPassword
        });
}