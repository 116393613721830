import React, { useState, useEffect } from 'react';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { Link, useParams } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import { getAssessmentProgressList,startAssessment } from '../../services/userassessment';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function AssessmentList(props) {

  const [assessmentList, setAssessmentList] = useState();
  const [left, setLeft] = React.useState([]);
  const [alertMsg, setAlertMsg] = useState(false);

  useEffect(() => {
       if (sessionStorage.getItem('packageId')!=null) {
           getAssessments();
       }else{
           window.location.href = '/packages';
       }
  }, []);
  const getAssessments = async () => {
    setAlertMsg(true);
    try {
      const response = await getAssessmentProgressList(sessionStorage.getItem('packageId'));
      setAssessmentList(response.data.data);
      setAlertMsg(false);
    } catch (error) {
      setAssessmentList(null);
      console.error(error);
      setAlertMsg(false);
    }
  };
  const start = async (seoName,type,assessmentId) => {
    if(type === "Core"){
      sessionStorage.setItem("coreAssessmentId",assessmentId);
    }
    sessionStorage.setItem("assessmentId",assessmentId);
    window.location.replace("/assessments/" + assessmentId+"/"+seoName);

  };

  return (
    <>
          <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/packages" className="btn btn-sm fw-bold btn-secondary">Back</Link>
                        </div>
                    </div>
                 
                </div>
            </div>
            {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
                <div id="kt_app_content_container" className="app-container  container-xxl ">
                  <div className="row g-5 g-xl-8 mb-80">
                    <div className="col-xl-12">
                      <h2 className="text-center">Your Assessments</h2>
                      <div className="">
                        <div className="card-body py-3">
                          <div className="table-responsive bg-white px-lg-20 px-0">
                            <table className="table table-striped fs-4 border border-dark-subtle" cellPadding="5">
                              <thead className="bg-body-secondary border border-dark-subtle">
                                <tr className="fw-bold">
                                  <th className="ps-md-10 ps-5 border-end-0">Assessment Title</th>
                                  <th className="border-start-0 border-end-0">Status</th>
                                  <th className="border-start-0">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {(assessmentList ? assessmentList : left).map((key, value) => (
                                  <tr>
                                    <td className="ps-md-10 ps-5">{key.name}</td>
                                    {key.status === 'N' ? (<>
                                      <td>
                                        <span className="fw-normal justify-content-center badge badge-danger fs-5 plr-20">Not Started{key.enableBtn}</span>
                                      </td>
                                      {key.enableBtn ? (<>
                                        <td><button onClick={() => start(key.seoName,key.type,key.assessmentId)} className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between fs-5 width-50">Start
                                          <i className="icon-xl fas fa-play-circle  fs-5"></i>
                                        </button>
                                        </td>
                                      </>) : (<>
                                        <td><button disabled className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between fs-5 width-50">Start
                                          <i className="icon-xl fas fa-play-circle pl20 fs-5"></i>
                                        </button>
                                        </td>
                                      </>)
                                      }
                                    </>) : (<>
                                      {key.status === 'S' ? (<>
                                        <td >
                                          <span className="fw-normal justify-content-center badge badge-primary fs-5 plr-20">In Progress</span>
                                        </td>

                                        <td><button onClick={() => start(key.seoName,key.type,key.assessmentId)} className="btn btn-secondary btn-sm d-flex align-items-center justify-content-between  width-50 fs-5">Resume
                                          <i className="icon-xl fas fa-check-circle pl20 fs-5"></i>
                                        </button>
                                        </td>
                                      </>) : (<>
                                        <td >
                                          <span className="fw-normal justify-content-center badge badge-success fs-5 plr-20">Completed</span>
                                        </td>

                                        <td>
                                        </td>
                                      </>)
                                      }
                                    </>)
                                    }
                                  </tr>
                                )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
           
    </>
  );
}

export default AssessmentList;