import React from 'react';
import { LOGIN_GOOGLE_AUTH_URL } from '../../Constants';
import googleLogo from '../../assets/images/google-logo.png';



function SocialLogin() {
    return (
        <div className="social-login">
            <a className="link-primary" href={LOGIN_GOOGLE_AUTH_URL}>
            <div className="text-center fw-semibold fs-6 google-login-btn">
                <img src={googleLogo} alt="Google" style={{width:"22px"}}/> Sign in with Google
                </div>
                </a>
        </div>

    );
}

export default SocialLogin;
