import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { deleteUser, getExpertUserList } from '../../../services/expertuser';
import AssignPackage from './AssignPackage';
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { _ } from "gridjs-react";
import './User.css';
import WarningModal from '../User/WarningModal';
import AlertModal from './AlertModal';


function UserTable({ recall }) {
    const [showAssign, setshowAssign] = useState(false);
    const [name, setName] = useState("");
    const [userData, setUserData] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState(false)


    const getUsers = async () => {
        try {
            const response = await getExpertUserList();
            setUserData(response.data.data)
        } catch (error) {
            setUserData([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, [])
    useEffect(() => {
        if (recall) {
            getUsers();
        }
    }, [recall])

    const deleteRecord = async (id) => {
            setShowWarning(true);
            setUserId(id);
    }

    const handleWarningModal = () => {
        setShowWarning(false);
    }

    const handleAlertModal = () => {
        setShowAlert(false);
    }

    const removeUser = async () => {
        setShowWarning(false);
        setAlertMsg(true);
        try {
            const response = await deleteUser(userId);
             if (response.data.status) {
                getUsers();
              }else{
                //show warning alert
                setShowAlert(true);
              }
            setAlertMsg(false);

        } catch (error) {
            setAlertMsg(false);
        }
    };


    return (
        <>
        <div className="col">

<div className="card card-flush h-md-100">
    <div className="card-header pt-7 ps-8 pb-30">
                <h1 className='text-center'>Users List</h1>
                <Grid 
        data={userData.map(x => [x.firstname,
            x.lastname, x.email,
            _(<a href={"/expert/users/edit/" + x.userId}><i className="fa-solid fa-pencil fs-4 text-gray-600 ps-1" ></i></a>),
            _(<a href={"/expert/users/view/" + x.userId}><i className="fa-solid fa-eye fs-4 text-gray-600 ps-1" ></i></a>),
            _(<a href={"/expert/users/" + x.firstname + " "+x.lastname+"/"+x.userId}><i className="fa-solid fa-user fs-4 text-gray-600 ps-1" ></i></a>),
            _(<i className="fa-solid fa-trash fs-4 text-gray-600 ps-1" onClick={() => deleteRecord(x.userId)}></i>)
          ])}
        columns={["First Name","Last Name", "Email","Edit", "View", "Assign", "Delete"]}
        pagination={{
          limit: 10
        }}
        sort={{
          multiColumn: false
        }}
        search
      
      />
            {showAssign && <AssignPackage name={name} />}
            {showWarning && <WarningModal userId={userId} show={showWarning} cancel={removeUser} onHide={handleWarningModal} message={"Do you really want to delete?"} />}
            {showAlert && <AlertModal show={showAlert} onHide={handleAlertModal} message={"Packages are assigned and cannot delete the user!"} />}
            </div>
            </div>
            </div>
        </>
    )
}
export default UserTable;