import { EXPERT_PROFILE, UPDATE_PASSWORD, USER_UPDATE_PROFILE,EXPERT_UPDATE_PROFILE, USER_GET_DASHBOARD_DETAILS } from "../Constants";
import { USER_SIGNUP } from "../Constants";
import { FORGOT_PASSWORD } from "../Constants";
import axiosInterceptor from '../helpers/axiosInterceptor';
import { USER_PROFILE } from "../Constants";
import { EXPERT_SIGNUP } from "../Constants";
import { USER_FAQS,EXPERT_FAQS,GET_EXCHANGE_RATE,EXPERT_SAVE_COMPANY,VALIDATE_USER_COUPON } from "../Constants";

function updatePassword(request) {
 return fetch(UPDATE_PASSWORD, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(request)
 })
   .then(data => data.json())
}

function userSignup(request) {
 return fetch(USER_SIGNUP, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(request)
 })
   .then(data => data.json())
}

function forgotPassword(request) {
 return fetch(FORGOT_PASSWORD, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(request)
 })
   .then(data => data.json())
}

function getUserProfile() {
      return axiosInterceptor.get(USER_PROFILE);
}
function getExpertProfile() {
  const userId = sessionStorage.getItem('userId')
  return axiosInterceptor.get(EXPERT_PROFILE);
}

function updateProfile(profileData) {
  return axiosInterceptor.post(USER_UPDATE_PROFILE,
      {
        "userId": profileData.userId,
        "firstname": profileData.firstname,
        "lastname": profileData.lastname,
        "timeZone": profileData.timeZone,
        "profileImage": profileData.profileImage
        });
}

function updateExpertProfile(profileData) {
  return axiosInterceptor.post(EXPERT_UPDATE_PROFILE,
      {
        "companyName": profileData.companyName,
        "firstname": profileData.firstname,
        "lastname": profileData.lastname,
        "timeZone": profileData.timeZone,
        "profileImage": profileData.profileImage,
        "industryType": profileData.industryType,
        "headCount": profileData.headCount,
        "about": profileData.about
        });
}

function expertSignup(request) {
 return fetch(EXPERT_SIGNUP, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(request)
 })
   .then(data => data.json())
}

function getUserFaqs() {
  return axiosInterceptor.get(USER_FAQS);
}

function getExpertFaqs() {
  return axiosInterceptor.get(EXPERT_FAQS);
}

function getDashboardDetails() {
  return axiosInterceptor.get(USER_GET_DASHBOARD_DETAILS);
}

function getExchangeRate() {
  return axiosInterceptor.get(GET_EXCHANGE_RATE);
}

function expertSaveCompany(request) {
  return axiosInterceptor.post(EXPERT_SAVE_COMPANY,
      {
        "companyName": request.companyName,
        "industryType": request.industryType,
        "headCount": request.headCount,
        "about": request.about
        });
}

function validateUserCoupon(code,packageId) {
  return axiosInterceptor.get(VALIDATE_USER_COUPON + code + "/" + packageId);
}

export {userSignup,getUserProfile,forgotPassword,updatePassword,expertSignup, updateProfile, 
        getExpertProfile,updateExpertProfile,getUserFaqs,getExpertFaqs,getDashboardDetails,getExchangeRate,
        expertSaveCompany,validateUserCoupon};