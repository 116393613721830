import React, { useEffect, useState } from "react";
import './App.css';
import './assets/style.css';
import './assets/plugins.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Themes from './components/UserAssessments/Themes';
import Overview from './components/User/Overview';
import apiToken from './apiToken';
import UserSignup from './components/Login/UserSignup';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import Packages from './components/UserAssessments/Packages';
import PackageSummary from './components/UserAssessments/PackageSummary';
import PaymentSuccess from './components/User/PaymentSuccess';
import Quiz from './components/Quiz/Quiz';
import UserAssessmentResult from './components/Report/UserAssessmentResult';
import PackageReport from './components/Report/PackageReport';
import AssessmentList from './components/UserAssessments/AssessmentList';
import Assessment from './components/UserAssessments/Assessment';
import AssesementPackages from "./components/Expert/Packages/AssesmentPackages";
import Header from "./components/User/Header";
import Footer from "./components/User/Footer";
import PackageList from './components/User/PackageList';
import User from "./components/Expert/User/ExpertUser";
import TicketsInfo from "./components/User/Support/TicketsInfo";
import UserSupport from "./components/User/Support/Usersupport";
import AssignPackage from "./components/Expert/User/AssignPackage";
import UserView from "./components/Expert/User/UserView";
import AddUser from "./components/Expert/User/AdduserModal";
import ExpertOverview from "./components/Expert/Overview/ExpertOverview";
import ExpertSignup from "./components/Login/ExpertSignup";
import Purchase from "./components/Expert/PackagePurchase/Purchase";
import ProfileOverview from "./components/Profile/ProfileOverview";
import EditProfile from "./components/Profile/EditProfile";
import ExpertPaymentSuccess from "./components/Expert/PackagePurchase/ExpertPaymentSuccess";
import ProfileChangePassword from "./components/Profile/ProfileChangePassword";
import RemoveLicenses from "./components/Expert/PackagePurchase/RemoveLicenses";
import Faqs from "./components/Faqs/Faqs";
import OAuth2RedirectHandler from "./components/User/Oauth2/OAuth2RedirectHandler";
import ExpertOnboarding from "./components/Login/ExpertOnboarding";
import LoginError from "./components/Login/LoginError";


function App() {
  const { token, setToken } = apiToken();
  const [userLoginStatus, setUserLoginStatus] = useState('');
  const [userType, setUserType] = useState();
  const [userStatus, setUserStatus] = useState();

  useEffect(() => {
    setUserStatus(sessionStorage.getItem("userStatus"));
    if (token) {
      const jwt = sessionStorage.getItem("jwt");
      if (jwt) {
        const payload = JSON.parse(atob(jwt.split(".")[1]));
        const expiration = payload.exp;
        if (expiration * 1000 < Date.now()) {
          setUserLoginStatus("N");
        } else {
          setUserLoginStatus("Y");
        }
      } else {
        setUserLoginStatus("N");
      }
    } else {
      setUserLoginStatus("N");
    }


  }, []);



  const getStatus = (status) => {
    setUserLoginStatus(status);
    setUserStatus(sessionStorage.getItem("userStatus"));
  }



  /*if(!token) {
    return <UserSignup />
  }*/

  return (
    <div className="">
      {userLoginStatus === 'Y' ?
        <>
        {userStatus === 'N' ? <>
        <BrowserRouter>
            <Routes>
            <Route path="/login-error" element={<LoginError />} />
            <Route path="/onboarding" element={<Navigate to='/login-error' />} />
            </Routes>
            </BrowserRouter>
        </> :
          userStatus === 'A' ? <>
          <BrowserRouter>
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
              <div className="app-page  flex-column flex-column-fluid " id="kt_app_page">
                <Header />
                <div className="app-container  container-fluid  container-xxl app-container-body" id="kt_app_header_container">
                  <Routes>
                  <Route path="/oauth2/redirect/:token" element={<OAuth2RedirectHandler token={setToken} status={getStatus}/>} />  
                  <Route path="/" element={<ExpertOverview />} />
                  <Route path="/login" element={<Navigate to='/overview' />} />
                    <Route path="/packages" element={<PackageList />} />
                    <Route path="/payment/:packageId/:couponId/success" element={<PaymentSuccess />} />
                    <Route path="/payment/:packageId/:couponId/success/:licenses" element={<ExpertPaymentSuccess />} />
                    <Route path="/overview" element={<ExpertOverview />} />
                    <Route path="/assessments/:seoName/quiz" element={<Quiz />} />
                    <Route path="/assessments/:seoName/results" element={<UserAssessmentResult />} />
                    <Route path="/packages/report" element={<PackageReport />} />
                    <Route path="/assessments" element={<AssessmentList />} />
                    <Route path="/assessments/:assessmentId/:seoName" element={<Assessment />} />
                    <Route path="/expert/packages" element={<AssesementPackages />} />
                    <Route path="/expert/users" element={<User />} />
                    <Route path="/support" element={<UserSupport />} />
                    <Route path="/support/view/:id" element={<TicketsInfo />} />
                    <Route path="/expert/users/:name/:id" element={<AssignPackage />} />
                    <Route path="/expert/users/view/:id" element={<UserView />} />
                    <Route path="/expert/users/add" element={<AddUser />} />
                    <Route path="/expert/users/edit/:id" element={<AddUser edit={true} />} />
                    <Route path="/purchase/:id" element={<Purchase />} />
                    <Route path="/profile" element={<ProfileOverview />} />
                    <Route path="/editProfile" element={<EditProfile />} />
                    <Route path="/changePassword" element={<ProfileChangePassword />} />
                    <Route path="/packages/purchase/:id" element={<Purchase />} />
                    <Route path="/packages/remove/:id" element={<RemoveLicenses />} />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/onboarding" element={<Navigate to='/overview' />} />
                  </Routes>
                </div>
              </div>
            </div>
            <Footer />
            </BrowserRouter>
            </> : <>
               <BrowserRouter>
             <Routes>
            <Route path="/onboarding" element={<ExpertOnboarding />} />
            <Route path="/oauth2/redirect/:token" element={<Navigate to='/onboarding' />} />  
            <Route path="/login-error" element={<LoginError />} />
                  <Route path="/" element={<Navigate to='/onboarding' />} />
                  <Route path="/login" element={<Navigate to='/onboarding' />} />
                    <Route path="/packages" element={<Navigate to='/onboarding' />} />
                    <Route path="/payment/:packageId/:couponId/success" element={<Navigate to='/onboarding' />} />
                    <Route path="/payment/:packageId/:couponId/success/:licenses" element={<Navigate to='/onboarding' />} />
                    <Route path="/overview" element={<Navigate to='/onboarding' />} />
                    <Route path="/assessments/:seoName/quiz" element={<Navigate to='/onboarding' />} />
                    <Route path="/assessments/:seoName/results" element={<Navigate to='/onboarding' />} />
                    <Route path="/packages/report" element={<Navigate to='/onboarding' />} />
                    <Route path="/assessments" element={<Navigate to='/onboarding' />} />
                    <Route path="/assessments/:assessmentId/:seoName" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/packages" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/users" element={<Navigate to='/onboarding' />} />
                    <Route path="/support" element={<Navigate to='/onboarding' />} />
                    <Route path="/support/view/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/users/:name/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/users/view/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/users/add" element={<Navigate to='/onboarding' />} />
                    <Route path="/expert/users/edit/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/purchase/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/profile" element={<Navigate to='/onboarding' />} />
                    <Route path="/editProfile" element={<Navigate to='/onboarding' />} />
                    <Route path="/changePassword" element={<Navigate to='/onboarding' />} />
                    <Route path="/packages/purchase/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/packages/remove/:id" element={<Navigate to='/onboarding' />} />
                    <Route path="/faqs" element={<Navigate to='/onboarding' />} />
            </Routes>
             </BrowserRouter>
             </>
             }
         
        </>
        :
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Login token={setToken} status={getStatus} />} />
            <Route path="/login" element={<Login token={setToken} status={getStatus} />} />
            <Route path="/signup" element={<UserSignup />} />
            <Route path="/expert-signup" element={<ExpertSignup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login-error" element={<LoginError />} />
            <Route path="/oauth2/redirect/" element={<Navigate to='/login-error' />} /> 
            <Route path="/oauth2/redirect/:token" element={<OAuth2RedirectHandler token={setToken} status={getStatus}/>} />  
            {userLoginStatus === 'N' ?
              <>
              <Route path="/overview" element={<Navigate to='/login' />} />
                <Route path="/packages" element={<Navigate to='/login' />} />
                <Route path="/payment/:packageId/:couponId/success" element={<Navigate to='/login' />} />
                <Route path="/payment/:packageId/:couponId/success/:licenses" element={<Navigate to='/login' />} />
                <Route path="/expert" element={<Navigate to='/login' />} />
                <Route path="/assessments/:seoName/quiz" element={<Navigate to='/login' />} />
                <Route path="/assessments/:seoName/results" element={<Navigate to='/login' />} />
                <Route path="/packages/report" element={<Navigate to='/login' />} />
                <Route path="/assessments" element={<Navigate to='/login' />} />
                <Route path="/assessments/:assessmentId/:seoName" element={<Navigate to='/login' />} />
                <Route path="/expert/packages" element={<Navigate to='/login' />} />
                <Route path="/expert/users" element={<Navigate to='/login' />} />
                <Route path="/support" element={<Navigate to='/login' />} />
                <Route path="/support/view/:id" element={<Navigate to='/login' />} />
                <Route path="/expert/users/:name/:id" element={<Navigate to='/login' />} />
                <Route path="/expert/users/view/:id" element={<Navigate to='/login' />} />
                <Route path="/expert/users/add" element={<Navigate to='/login' />} />
                <Route path="/expert/users/edit/:id" element={<Navigate to='/login' />} />
                <Route path="/purchase/:id" element={<Navigate to='/login' />} />
                <Route path="/profile" element={<Navigate to='/login' />} />
                <Route path="/editProfile" element={<Navigate to='/login' />} />
                <Route path="/changePassword" element={<Navigate to='/login' />} />
                <Route path="/packages/purchase/:id" element={<Navigate to='/login' />} />
                <Route path="/packages/remove/:id" element={<Navigate to='/login' />} />
                <Route path="/faqs" element={<Navigate to='/login' />} />
                <Route path="/onboarding" element={<Navigate to='/login' />} />
              </> : ""}
          </Routes>
        </BrowserRouter>
      }
    </div>
  );
}

export default App;
