import React, { useState, useEffect } from 'react';
import './style.css';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { useParams } from 'react-router-dom';
import bgImage from "../../assets/images/Manthinking-bro.png";
import {startAssessment,getNextQuestion} from '../../services/userassessment';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function Quiz() {
    const params = useParams();
    const seoName = params.seoName;
    const [assessmentId, setAssessmentId] = useState();
    const [itemId, setItemId] = useState();
    const [question, setQuestion] = useState();
    const [l1, setL1] = useState(0);
    const [l2, setL2] = useState(0);
    const [value, setValue] = useState();
    const [trueOp, setTrueOp] = useState("True");
    const [falseOp, setFalseOp] = useState("False");
    const [questionNo, setQuestionNo] = useState();
    const [yesBgClr, setYesBgClr] = useState();
    const [noBgClr, setNoBgClr] = useState("rgb(204 204 204)");
    const [animation, setAnimation] = useState("false");
    const [alertMsg, setAlertMsg] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('assessmentId')!=null) {
        setAssessmentId(sessionStorage.getItem('assessmentId'));
        setQuestionNo(1);
        startQuiz();
        }else{
          window.location.href = '/assessments';
        }
    }, []);
    const startQuiz = async () => {
        setAlertMsg(true);
        try {
            const response = await startAssessment(sessionStorage.getItem('assessmentId'));
            setAlertMsg(false);
            if (response.data.data != null) {
                setItemId(response.data.data.itemId);
                setQuestion(response.data.data.question);
            }
        } catch (error) {
            setAlertMsg(false);
            console.error(error);
            window.location.href = '/assessments';
        }
    };

    const onChangeValue = (val) => {
        setValue(val);
        if (val === "yes") {
            setYesBgClr("green");
            setNoBgClr("rgb(204 204 204)");
        } else {
            setNoBgClr("green");
            setYesBgClr("rgb(204 204 204)");
        }
        window.setTimeout(function () {
            nextQuestion(val);
        }, 1000);
    }

    const nextQuestion = async (val) => {
        if (val != null) {
            const response = await getNextQuestion(itemId, val, questionNo,l1,l2);
            setYesBgClr("rgb(204 204 204)");
            setNoBgClr("rgb(204 204 204)");
            setAnimation(true);
            setValue(null);
            if (response.data.data != null) {
                if (response.data.data.next) {
                    //display next question
                    setQuestionNo(questionNo + 1);
                    setQuestion(response.data.data.question);
                    setItemId(response.data.data.itemId);
                    setL1(response.data.data.l1);
                    setL2(response.data.data.l2);
                } else {
                    const resultUrl = '/assessments/' + seoName + '/results';
                    window.location.replace(resultUrl);
                }
            }
        }
    };


    return (
        <>
                    <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
                        <div className="d-flex flex-column flex-column-fluid">
                            <div id="kt_app_content" className="app-content  flex-column-fluid " >
                            {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}

                                <div id="kt_app_content_container" className="app-container  container-xxl ">
                                    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                        <div className="col">

                                            <div className="card card-flush h-md-100">
                                                <div className="card-body pt-7">
                                                    <div className="card-title align-items-start flex-column text-center">
                                                        <h3 className="card-label fw-bold text-gray-800 fs-35">Assessment : {seoName}</h3>
                                                    </div>
                                                </div>
                                                <form method="post" className="show-section p-40">

                                                    <section className="steps">
                                                        <div className="step-inner">

                                                            <div className="quiz-question">
                                                                {questionNo}. {question}
                                                            </div>

                                                            <fieldset id="step1">
                                                                <div className="input-field">
                                                                    <div className="option" style={{ backgroundColor: yesBgClr }}>
                                                                        A
                                                                    </div>
                                                                    <div className={animation ? "radio-field bounce-left" : null}>
                                                                        <input type="radio" id={questionNo} name={questionNo} value="yes" 
                                                                        checked={value === "yes"}
                                                                        onChange={(e) => onChangeValue(e.target.value)} style={{ backgroundColor: yesBgClr }} />
                                                                        <label>{trueOp}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-field">
                                                                    <div className="option" style={{ backgroundColor: noBgClr }}>
                                                                        B
                                                                    </div>
                                                                    <div className={animation ? "radio-field bounce-left delay-100" : null}>
                                                                        <input type="radio" id={questionNo} name={questionNo} value="no" 
                                                                        checked={value === "no"}
                                                                        onChange={(e) => onChangeValue(e.target.value)} style={{ backgroundColor: noBgClr }} />
                                                                        <label>{falseOp}</label>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <img className="thinking_bro" src={bgImage} alt="thinking-bro" />
                                                        </div>

                                                    </section>

                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
        </>
    );
}

export default Quiz;