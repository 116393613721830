import React, { useState, useEffect } from 'react';
import { addUser, updateUser } from '../../../services/expertuser';
import { useParams } from 'react-router-dom';
import { getUserDetails } from '../../../services/expertuser';
import { Alert } from "react-bootstrap";
import { Link } from 'react-router-dom';


function AddUser({edit}) {
    const {id} = useParams();
    const [userDetails, setUserDetails] = useState({ firstname: "", lastname: "", email: "" });
    const [errors, setErrors] = useState({ firstname: false, lastname: false, email: false });
    const [alertError, setAlertError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const getDetails = async () => {
        try {
            const response = await getUserDetails(id);
            setUserDetails(response.data.data);

        } catch (error) {
            setUserDetails({});
            console.error(error);
        }
    };
    useEffect(() => {
        getDetails();
    }, [])
    const handleUserDataChange = (e) => {
        setErrors({ ...errors, [e.target.name]: false })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }
    
    const handleCloseForm = () => {
        setUserDetails({ firstname: "", lastname: "", email: "" })
        window.location.href = '/expert/users'
        // history.push("/users");
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
    const checkErrors = () => {
        const { firstname, lastname, email } = userDetails
        if (!firstname) {
            setErrors({ ...errors, firstname: true })
            return true;
        }
        if (!lastname) {
            setErrors({ ...errors, lastname: true })
            return true;
        }
        if (!email || !validateEmail(email)) {
            setErrors({ ...errors, email: true })
            return true;
        }
        return false;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const { firstname, lastname, email } = userDetails
        if (!checkErrors()) {
            try {
                if(edit) {
                    const response = await updateUser( firstname, lastname, userDetails.userId,timeZone );
                    if(response.data.status){
                       handleCloseForm();
                    }
                } else {
                const response = await addUser( firstname, lastname, email,timeZone );
                if (response.data.status) {
                    handleCloseForm();
                } else {
                    setErrMsg(response.data.message);
                    setAlertError(true);
                }
                }
            } catch (error) {
                console.error(error);
                setErrMsg("Internal Server Error");
                setAlertError(true);
            }
        }
    }
    return (
        <>
               <div className='d-flex flex-stack'>
                <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            Users
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">Users</li>
                        </ul>
                    </div>
                </div>
               
            </div>
             <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                <div className="col">

                    <div className="card card-flush h-md-100">
                        <div className="card-header pt-7 ps-8" style={{marginLeft:"30px"}} >
                        <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                {errMsg}
                            </Alert>
                            <h1 className="text-gray-900 fw-bolder pt-9">
                            {edit?"Edit User":"Add User"}
                            </h1>
                         
                    <form className="form col-md-12 mt-10" id="kt_sign_in_form" onSubmit={handleSubmit}>
                        

                        <div className="fv-row mb-8 col-md-6">
                            <input type="text" onChange={handleUserDataChange} placeholder="First Name" name="firstname" autoComplete="off" className="form-control bg-transparent" value={userDetails.firstname}/>
                            {errors.firstname && <div class="invalid-error">
                            <span className="input-error-text"><small> Please Enter Firstname</small></span>
                            </div>}

                        </div>

                        <div className="fv-row mb-8 col-md-6">
                            <input type="text" onChange={handleUserDataChange} placeholder="Last Name" name="lastname" autoComplete="off" className="form-control bg-transparent" value={userDetails.lastname}/>
                            {errors.lastname && <div class="invalid-error">
                            <span className="input-error-text" ><small>Please Enter Lastname</small></span>
                            </div>}
                        </div>

                        <div className="fv-row mb-8 col-md-6">
                            <input type="text" onChange={handleUserDataChange} placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" value={userDetails.email} disabled={edit}/>
                            {errors.email && <div class="invalid-error">
                            <span className="input-error-text" ><small>Please Enter Valid Email</small></span>
                            </div>}
                        </div>

                        <div className="mb-10">
                            <button type="submit" id="kt_sign_up_submit" className="btn btn-primary" style={{width:"fit-content"}}>

                                <span className="indicator-label">
                                    {edit?"Update":"Submit"}</span>

                                <span className="indicator-progress">
                                    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                            <Link to="/expert/users" className="btn btn-secondary ml-30" style={{width:"fit-content"}}>

                                <span className="indicator-label">Cancel</span>

                            </Link>
                        </div>
                    </form>
</div>
</div>

</div> 
</div> 
      </>
    )
}
export default AddUser;

