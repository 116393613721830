import axiosInterceptor from '../helpers/axiosInterceptor';
import { GET_ASSESSMENTDETAILS_BY_ID, GET_FIRST_ASSESSMENT } from "../Constants";
import { GET_ASSESSMENT_LIST } from "../Constants";
import { GET_ASSESSMENT_RESULT } from "../Constants";
import { START_ASSESSMENT } from "../Constants";
import { GET_NEXT_QUESTION } from "../Constants";
import { GET_ASSESSMENT_PROGRESS_LIST } from "../Constants";
import { GET_ASSESSMENT_BY_NAME } from "../Constants";

function getAssessment(packageId) {
      return axiosInterceptor.get(GET_FIRST_ASSESSMENT + packageId);
}


function getAssessmentByName(seoName,packageId) {
      return axiosInterceptor.get(GET_ASSESSMENT_BY_NAME + seoName + "/" + packageId);
}

function getAssessmentById(assessmentId) {
      return axiosInterceptor.get(GET_ASSESSMENTDETAILS_BY_ID + assessmentId );
}

function getAssessmentList(packageId) {
      return axiosInterceptor.get(GET_ASSESSMENT_LIST + packageId);
}

function getAssessmentResult(assessmentId,packageId,coreAssessmentId) {
      return axiosInterceptor.get(GET_ASSESSMENT_RESULT + assessmentId+"/packageId/"+packageId+"/coreAssId/"+coreAssessmentId);
}

function getAssessmentProgressList(packageId) {
      return axiosInterceptor.get(GET_ASSESSMENT_PROGRESS_LIST + packageId);
}


function startAssessment(assessmentId) {
      return axiosInterceptor.post(START_ASSESSMENT,
      {
          id : assessmentId
      });
}

function getNextQuestion(itemId,result,questionNo,l1,l2) {
      return axiosInterceptor.post(GET_NEXT_QUESTION,
      {
          itemId : itemId,
          result : result,
          questionNo : questionNo,
          l1 : l1,
          l2 : l2
      });
}


export {getAssessment,getAssessmentByName,getAssessmentList,getAssessmentResult,
getAssessmentProgressList,startAssessment,getNextQuestion,getAssessmentById};