import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { expertRemainingLicenses, expertRemoveLicense } from '../../../services/userpackage';
import SuccessModal from '../User/SuccessModal';
import { Link } from 'react-router-dom';
import { expertCheckoutSession } from '../../../services/checkoutsession';
import loading from "../../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import WarningModal from '../User/WarningModal';


function RemoveLicenses() {
    const { id } = useParams();
    const [packageDetails, setPackage] = useState({ packageName: "", licenseCount: 0, price: 0.0 });
    const [newlicenseCount, setnewlicenseCount] = useState(0);
    const [showWarning, setShowWarning] = useState(false)
    const [alertMsg, setAlertMsg] = useState(false);
    const [errLicense, setErrLicense] = useState(false);
    const [packageId, setPackageId] = useState();

    var licenseInp;

    useEffect(() => {
        licenseInp = document.getElementById("licenses");
    });

    useEffect(() => {
        getExpertPackages()
    }, [])
    const getExpertPackages = () => {
        expertRemainingLicenses(id).then((res) => {
            setPackage(res.data.data);
        })
    }
    const handleWarningModal = () => {
        setShowWarning(false);
    }
    const remove = () => {
        if (newlicenseCount <= 0) {
            setErrLicense(true);
            licenseInp.classList.add("error-input-border");
        } else if (newlicenseCount > packageDetails.licenseCount) {
            setErrLicense(true);
            licenseInp.classList.add("error-input-border");
        } else {
            setShowWarning(true);
            setPackageId(id);
        }
    }

    const removeLicense = async () => {
        setShowWarning(false);
        setAlertMsg(true);
        try {
            const res = await expertRemoveLicense(id, newlicenseCount);
             if (res.data.data) {
                        window.location.replace("/expert/packages");
              }
            setAlertMsg(false);

        } catch (error) {
            setAlertMsg(false);
            window.location.replace("/expert/packages");
        }
    };

    return (
        <>
              <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >

                       
<div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
        Packages
    </h1>

    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

        <li class="breadcrumb-item text-muted">
            <Link to="/" class="text-muted text-hover-primary">Home</Link>
        </li>

        <li class="breadcrumb-item">
            <span class="bullet bg-gray-500 w-5px h-2px"></span>
        </li>

        <li class="breadcrumb-item text-muted">Packages</li>

    </ul>
</div>



</div>
            <div class="card mt-5 mb-80" id="kt_profile_details_view">

                <div class="card-header cursor-pointer py-5">

                    <div class="card-title m-0">
                        <h3 class="fw-bold m-0">Remove Licenses</h3>
                    </div>
                </div>

                <div class="card-body p-9">
                    {alertMsg ?
                        <SweetAlert
                            title=""
                            timeout={10000}
                            showConfirm={false}
                            style={{ width: "200px", height: "100px" }}
                        > <img src={loading} width="50px" height="50px" />
                        </SweetAlert>
                        : <></>}
                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">Package Name</label>

                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800">{packageDetails.packageName}</span>
                        </div>

                    </div>

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">Package Price</label>

                        <div class="col-lg-8">
                            <span class="fw-bold fs-6 text-gray-800">${Number(packageDetails.price).toFixed(2)}</span>
                        </div>

                    </div>

                    {packageDetails.licenseCount > 0 && <>

                        <div class="row mb-7">

                            <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                                Unassigned Quantity
                            </label>

                            <label class="col-lg-4 fw-bold fs-6 text-gray-800">
                                {packageDetails.licenseCount}
                            </label>


                        </div>
                    </>}

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                            Enter Quantity
                        </label>

                        <div class="col-lg-2">
                            <input type="number" id="licenses" name="licenses" class="form-control form-control-lg form-control-solid" placeholder="No. of Licenses" value={newlicenseCount} onChange={(e) => setnewlicenseCount(e.target.value)} />
                            {errLicense == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Valid Quantity.</small></span> : null}
                        </div>
                    </div>

                    <div class="row mb-7">

                        <label class="col-lg-3 fw-bold fs-6 text-gray-800">
                            Price
                        </label>

                        <label class="col-lg-4 fw-bold fs-6 text-gray-800">
                            ${Number(newlicenseCount * packageDetails.price).toFixed(2)}
                        </label>

                    </div>

                    <div class="row mb-7">
                        <div class="col-lg-1">
                        </div>
                        <button type="submit" class="col-lg-1 btn btn-sm btn-primary align-self-center" onClick={remove}>Refund</button>
                        <Link to="/expert/packages" class="col-lg-1 btn btn-sm btn-secondary align-self-center ml-30">Cancel</Link>

                    </div>

                </div>
                {showWarning && <WarningModal id={id} show={showWarning} cancel={removeLicense} onHide={handleWarningModal} message={"Do you really want to refund?"} />}
            </div>
        </>
    )
}
export default RemoveLicenses;