import { Button, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { reportDownload } from '../../../services/userpackage';

function PackagesList({ data,userId }) {
    const report = (packageId) => {
        console.log(packageId);
        sessionStorage.setItem("packageId",packageId);
        downloadReport();
    };
    const downloadReport = async () => {
        try {
            const response = await reportDownload(userId,sessionStorage.getItem("packageId"));
            if(response.data.status){
                window.location.href = response.data.data;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Accordion>
            {data.length > 0 && data.map((item, index) => {
                return (
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.packageName}</Accordion.Header>
                        <Accordion.Body>
                            {item.status === "C" && <button className='download-styles mb-3' onClick={() => report(item.packageId)}>
                                <i class="fa-solid fa-cloud-arrow-down"></i> Download
                            </button>}
                            <Table bordered >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.assessmentDTOs && item.assessmentDTOs.map((assesment) => {
                                        return (
                                            <tr>
                                                <td>{assesment.name}</td>
                                                <td>{assesment.status === "N" ? <div>Not Started</div> : <div>Completed</div>}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>

                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}

        </Accordion>
    );
}

export default PackagesList;