import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { expertSignup } from '../../services/user';
import { Link } from 'react-router-dom';
import RightBanner from './RightBanner';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";
import ReCAPTCHA from 'react-google-recaptcha';
import { REACT_APP_SITE_KEY } from '../../Constants';
import logo from "../../assets/images/resetlogo.png";
import ExpertSocialSignup from './ExpertSocialSignup';



export default function ExpertSignup() {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
   // const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState();
    const [errFirstname, setErrFirstname] = useState(false);
    const [errLastname, setErrLastname] = useState(false);
    //const [errComapnyName, setErrCompanyName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);
    const captchaRef = useRef(null);

    var firstnameInp, lastnameInp, companyNameInp, emailInp;

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    useEffect(() => {

        firstnameInp = document.getElementById("firstname-input");
        lastnameInp = document.getElementById("lastname-input");
       // companyNameInp = document.getElementById("companyname-input");
        emailInp = document.getElementById("email-input");
    });

    const validateFields = () => {
        if (firstname === "") {
            setErrFirstname(true);
            firstnameInp.classList.add("error-input-border");
            return false;
        }
        if (lastname == "") {
            setErrLastname(true);
            lastnameInp.classList.add("error-input-border");
            return false;
        }
      /*  if (companyName == "") {
            setErrCompanyName(true);
            companyNameInp.classList.add("error-input-border");
            return false;
        }*/
        if (email == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            return false;
        }
        return true;
    }

    const onChangeFirstname = (val) => {
        if (errFirstname == true) {
            firstnameInp.classList.remove("error-input-border");
            setErrFirstname(false);
        }
        setFirstname(val);
    }
    const onChangeLastname = (val) => {
        if (errLastname == true) {
            lastnameInp.classList.remove("error-input-border");
            setErrLastname(false);
        }
        setLastname(val);
    }
   /* const onChangeCompanyName = (val) => {
        if (errComapnyName == true) {
            companyNameInp.classList.remove("error-input-border");
            setErrCompanyName(false);
        }
        setCompanyName(val);
    }*/
    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            const captchaToken = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (captchaToken) {
            try {
                setAlertMsg(true);
                const response = await expertSignup({ firstname, lastname, email,timeZone,captchaToken });
                if (response.status) {
                    setAlertMsg(false);
                    setFirstname("");
                    setLastname("");
                   // setCompanyName("");
                    setEmail("");
                    setAlertError(false);
                    setAlertSuccess(true);
                    setSuccessMsg("Registered Successfully,Credentials has been sent to your email");
                } else {
                    setAlertMsg(false);
                    setErrMsg(response.message);
                    setAlertError(true);
                }
            } catch (error) {
                console.log(error);
                setErrMsg("Internal Server Error");
                setAlertMsg(false);
                setAlertError(true);
            }
        } else {
            setAlertMsg(false);
            setErrMsg("Please confirm that you are not a robot");
            setAlertError(true);
        }
        }

    }

    return (
        <>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2 right-text">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <Link to="/overview" title="Logo" className="logo">
                <img src={logo} title="logo" alt="logo" className="logo-default" />
            </Link>
                        <div className="w-lg-500px p-10">
                            <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                {errMsg}
                            </Alert>
                            <Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                {successMsg}
                            </Alert>
                            {alertMsg ?
                                <SweetAlert
                                    title=""
                                    timeout={10000}
                                    showConfirm={false}
                                    style={{ width: "200px", height: "100px" }}
                                > <img src={loading} width="50px" height="50px" />
                                </SweetAlert>
                                : <></>}
                            <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
                                <div className="text-center mb-11">
                                    <h1 className="text-gray-900 fw-bolder mb-3">
                                        Sign Up
                                    </h1>

                                </div>

                                <div className="fv-row mb-2">
                                    <input type="text" value={firstname} id="firstname-input"
                                        onChange={(e) => onChangeFirstname(e.target.value)} placeholder="First Name" name="firstname" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errFirstname == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Firstname.</small></span> : null}
                                </div>

                                <div className="fv-row mb-2">
                                    <input type="text" value={lastname} id="lastname-input"
                                        onChange={(e) => onChangeLastname(e.target.value)} placeholder="Last Name" name="lastname" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errLastname == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Lastname.</small></span> : null}
                                </div>

                              {/*  <div className="fv-row mb-2">
                                    <input type="text" value={companyName} id="companyname-input"
                                        onChange={(e) => onChangeCompanyName(e.target.value)} placeholder="Company Name" name="companyName" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errComapnyName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Company Name.</small></span> : null}
                            </div> */}

                                <div className="fv-row mb-2">
                                    <input type="text" value={email} id="email-input"
                                        onChange={(e) => onChangeEmail(e.target.value)} placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                                </div>

                                <div className="fv-row mb-2">
        <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} ref={captchaRef}/>
        </div>


                                <div className="d-grid mb-2">
                                    <button type="submit" id="kt_sign_up_submit" className="btn btn-primary">

                                        <span className="indicator-label">
                                            Sign up</span>

                                        <span className="indicator-progress">
                                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>

                               <div className='text-center'>
                                    <b>Or</b>
                                </div>
                                <ExpertSocialSignup />  

                                <div className="text-gray-500 text-center fw-semibold fs-6 mt-30">
                                    Already have an Account?

                                    <Link to="/login" className="link-primary fw-semibold">
                                        Sign in
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                <RightBanner />
            </div>
        </>
    )
}
