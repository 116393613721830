import { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import AssesmentPackageTable from './ExpertPackageTable';
import getExpertPackageList from '../../../services/expertpackagelist';
import { getExchangeRate } from '../../../services/user';



const PINK = '#419eac';
const BLUE = 'white';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div
            style={{ backgroundColor: isCurrentEventKey ? PINK : BLUE }}
            onClick={decoratedOnClick}
            className='p-3 fs-4'
        >
            {isCurrentEventKey? <i className="fa-solid fa-square-minus fs-3 minus-styles pe-4"></i>: <i className="fa-solid fa-square-plus fs-3 pe-4"></i>
            }
            {children}
        </div>
    );
}

function ExpertPackageAccordion() {
    const [packages, setPackages] = useState([]);
    useEffect(()=>{
        getExpertPackages();
        if (!sessionStorage.getItem('exchangeRate')) {
            getRate();
             }
    },[]);
    const getRate = async () => {
        try {
            const response = await getExchangeRate();
            let userData = response.data;
            if(userData.data!=null){
                if(userData.data.exchangeRate!=null){
                  sessionStorage.setItem('currencyCode', userData.data.currencyCode); 
                  sessionStorage.setItem('exchangeRate', userData.data.exchangeRate); 
                  console.log(userData.data);
              }
              }
        } catch (error) {
            console.error(error);
        }
    };
    const getExpertPackages = async () => {
        try {
          const response = await getExpertPackageList();
         // console.log(response.data.data);
          setPackages(response.data.data);
        } catch (error) {
            setPackages([]);
          console.error(error);
        }
      };
    return (
        <Accordion defaultActiveKey="0">
            {packages.length>0&&packages.map((packageData, index) => {
                return (
                    <Card key = {packageData.themeId}>
                        <Card.Header className='p-0'>
                            <ContextAwareToggle eventKey={index.toString()}>{packageData.themeName}</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body className='p-10'>
                            <AssesmentPackageTable tableData={packageData.packageList}/>
                            
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })}
        </Accordion>
    );
}

export default ExpertPackageAccordion;