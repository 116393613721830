import React, { useState, useEffect } from 'react';
import getExpertUserList from '../../../services/expertuser';
import Table from 'react-bootstrap/Table';
import UserSupportTable from './UserSupportTable';
import CreateTicket from './CreateTicket';
import { Link } from 'react-router-dom';


function UserSupport() {
    const [showCreateTicket, setShowCreateTicket] = useState(false);
    const userType = sessionStorage.getItem('userType');
    const [recall, setRecall] =useState(false)
    const handleshowCreateTicket =() =>{
        setShowCreateTicket(!showCreateTicket)
    }
    return (
        <>
            <div className='d-flex flex-stack'>
                <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6 " >
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Support
                        </h1>
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li class="breadcrumb-item text-muted">
                                <Link to="/" class="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">Support</li>
                        </ul>
                    </div>
                </div>
                {userType==='U' && <div class="d-flex align-items-center gap-2 gap-lg-3" onClick={handleshowCreateTicket}>
                    <a class="btn btn-sm fw-bold btn-primary">Create Ticket</a>
                </div>}
                {userType==='EU' && <div class="d-flex align-items-center gap-2 gap-lg-3" onClick={handleshowCreateTicket}>
                    <a class="btn btn-sm fw-bold btn-primary">Create Ticket</a>
                </div>}
                </div>
            <CreateTicket show={showCreateTicket} handleClose={handleshowCreateTicket} recallAPI={(recall)=>setRecall(recall)}/>
            <div className="mb-80">
                <UserSupportTable recall={recall}/>
            </div>
        </>
    )
}
export default UserSupport;