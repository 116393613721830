import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../services/user';
import RightBanner from './RightBanner';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";
import logo from "../../assets/images/resetlogo.png";



function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [errEmail, setErrEmail] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState();


    var emailInp;

    useEffect(() => {
        emailInp = document.getElementById("email-input");
    });

    const validateFields = () => {
        if (email == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            return false;
        }
        return true;
    }

    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            try {
                setAlertMsg(true);
                const response = await forgotPassword({
                    email
                });
                if (response.status) {
                    setAlertMsg(false);
                    setEmail("");
                    setAlertError(false);
                    setAlertSuccess(true);
                    setSuccessMsg("Reset Password Link has been sent to your email");
                } else {
                    setErrMsg(response.message);
                    setAlertError(true);
                    setAlertMsg(false);
                }
            } catch (error) {
                // console.error(error);
                setErrMsg("Internal Server Error");
                setAlertMsg(false);
                setAlertError(true);
            }
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2 right-text">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <Link to="/overview" title="Logo" className="logo">
                <img src={logo} title="logo" alt="logo" className="logo-default" />
            </Link>
                        <div className="w-lg-500px p-10">
                            <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                {errMsg}
                            </Alert>
                            <Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                {successMsg}
                            </Alert>
                            {alertMsg ?
                                <SweetAlert
                                    title=""
                                    timeout={10000}
                                    showConfirm={false}
                                    style={{ width: "200px", height: "100px" }}
                                > <img src={loading} width="50px" height="50px" />
                                </SweetAlert>
                                : <></>}
                            <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
                                <div className="text-center mb-11">
                                    <h1 className="text-gray-900 fw-bolder mb-3">
                                        Forgot Password ?
                                    </h1>
                                    <div class="text-gray-500 fw-semibold fs-6">
                                        Enter your email to reset your password.
                                    </div>
                                </div>
                                <div className="fv-row mb-8">
                                    <input type="text" value={email} id="email-input"
                                        onChange={(e) => onChangeEmail(e.target.value)} placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                                </div>


                                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                    <button type="submit" id="kt_password_reset_submit" class="btn btn-primary me-4">

                                        <span className="indicator-label">
                                            Submit</span>

                                        <span className="indicator-progress">
                                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                    <Link to="/login" className="btn btn-secondary">Cancel</Link>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>

                <RightBanner />
            </div>
        </>
    )
}
export default ForgotPassword;