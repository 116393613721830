import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { unAssignPackage } from '../../../services/userpackage';


function WarningModal(props) {


    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                    {/* <h4> */}
                    <i class="fa-solid fa-circle-exclamation" style={{ fontSize: "80px", marginBottom: "20px" }}></i>
                    {/* </h4> */}
                    <p className='swal-text'>
                        {props.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-warning" onClick={props.cancel}>Yes</button>
                    <button className="btn btn-secondary" onClick={props.onHide}>No</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default WarningModal;


