import React, { useState, useEffect } from 'react';

function PackagePrice(props) {
    const currencyCode = sessionStorage.getItem("currencyCode");
    const exchangeRate = sessionStorage.getItem("exchangeRate");
   
    return <span>{exchangeRate ? 
       <> {props.tax ? "₹"+ Number(Math.round((props.price * exchangeRate) + 10)).toFixed(2) : "₹" + Number(Math.round(props.price * exchangeRate)).toFixed(2)} </>
        :
       <>{props.tax ? "$"+(props.price +10) : "$"+props.price}</> 
        }</span>;
        
}
export default PackagePrice;


