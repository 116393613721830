import { Link } from 'react-router-dom';

function Footer() {
  
  return(
  <>
  <div id="kt_app_footer" className="app-footer">
        <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3  justify-content-center">
<div className="order-2 order-md-1 footer-text">
    <span className="me-1">2024&copy;</span>
    <Link to="/overview" className="text-black">Integrated Diagnostic System Inc. All rights reserved.</Link>
</div>

       </div>
</div>
  </>
  );
}

export default Footer;