import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getToken } from '../../../services/login';
import { jwtDecode } from 'jwt-decode';
import Login from '../../Login/Login';


function OAuth2RedirectHandler(props) {
    const params = useParams();
    const [jwtToken, setJwtToken] = useState();

 useEffect(() => {
     const token = params.token;
     if(token == null){
      window.location.href = '/login-error';
     }else{
     getTokenResponse(); 
     }
 },[]);

    const getTokenResponse = async () => {
    const token = params.token;
          try {
              const response = await getToken({
                  token
              });
              if (response.status) {
                  props.token(response.data);
                  props.status("Y");
                  setJwtToken("Y");
                  let tokenInfo = jwtDecode(response.data.token);
                  let tokenObj = JSON.parse(tokenInfo.token);
                  let userStatus = tokenObj['status'];
                  let userId = tokenObj['userId'];
                  let userType = tokenObj['userType'];
                  sessionStorage.setItem('userId', userId);
                  sessionStorage.setItem('userType', userType);
                  sessionStorage.setItem('userStatus', userStatus);
                  sessionStorage.setItem('jwt', response.data.token);
                  if(userStatus == "A"){
                  window.location.href = '/overview';
                  }else{
                    window.location.href = '/onboarding';
                  }
              }else{
                setJwtToken("N");
                props.status("N");
                window.location.href = '/login';
              }
          } catch (error) {
            setJwtToken("N");
            props.status("N");
            window.location.href = '/login';
          }

  }

   
    return (
        <>
          
        </>
    )
}

export default OAuth2RedirectHandler;